<script>
import { getPatientSessionV3, getPatientSessionV2} from '@/services/sessionServices'
import { getCurrentPatient } from '@/services/patientServices';
import TitleHeader from '@/components/TitleHeader.vue'

export default {
    name: 'Case',
    components: {
        TitleHeader
      },
    data() {
        return {
        sessions: null,
        raw_sessions: null,
        images_data: [],
        selectedDate: '',
        patientData: [],
        }
    },
    mounted() {
            this.get_all_data();
    },
    computed: {
        hasEyeType0() {
            return this.sortedFilteredSessions.some(session => {
                const summary = session.summary.EyeType0;
                return summary && !(summary.average_confidence === null &&
                                    summary.case_count === 0 &&
                                    summary.highest_abnormality === "None" &&
                                    summary.highest_grading === "None");
            });
        },
        hasEyeType1() {
            return this.sortedFilteredSessions.some(session => {
                const summary = session.summary.EyeType1;
                return summary && !(summary.average_confidence === null &&
                                    summary.case_count === 0 &&
                                    summary.highest_abnormality === "None" &&
                                    summary.highest_grading === "None");
            });
        },
        hasEyeType2() {
            return this.sortedFilteredSessions.some(session => {
                const summary = session.summary.EyeType2;
                return summary && !(summary.average_confidence === null &&
                                    summary.case_count === 0 &&
                                    summary.highest_abnormality === "None" &&
                                    summary.highest_grading === "None");
            });
        },
        availableDates() {
            if (!this.sessions) return []
            return [...new Set(this.sessions.map(session => 
            new Date(session.SessionDate).toISOString().split('T')[0]
            ))].sort().reverse()
        },

        sortedFilteredSessions() {
            if (!this.sessions) return []
            let sessions = [...this.sessions]
            
            // Sort by date (newest first)
            sessions.sort((a, b) => new Date(b.SessionDate) - new Date(a.SessionDate))
            
            // Filter by selected date if any
            if (this.selectedDate) {
            sessions = sessions.filter(session => 
                session.SessionDate.startsWith(this.selectedDate)
            )
            }
            
            return sessions
        },

        periodDates() {
          if (!this.raw_sessions || this.raw_sessions.length < 2) return null;

          let filteredSessions = this.raw_sessions;
          
          const firstSession = filteredSessions[0]; 
          const latestSession = filteredSessions[filteredSessions.length - 1];
          
          return {
            firstDate: new Date(firstSession.SessionDate).toLocaleDateString(),
            latestDate: new Date(latestSession.SessionDate).toLocaleDateString(),
          };

        }
    },

    methods: {
        get_all_data(){
          this.fetchSessionData();
          this.getPatientData();
        },

        compareGrades(firstGrade, latestGrade) {
          const gradeValues = {
            'Grade_0': 0,
            'Grade_1': 1,
            'Grade_2': 2,
            'normal': 0
          };
          
          return gradeValues[firstGrade] > gradeValues[latestGrade];
        },

        async fetchSessionData() {
            const patientUuid = this.$route.params.uuid
            try {
                const sessionsData = await getPatientSessionV3(patientUuid)

                this.sessions = sessionsData.sessions.map(session => ({
                    ...session,
                    showSummary: false, // Initialize all summaries as hidden
                    showNotes: false
                }))

                this.raw_sessions = sessionsData.sessions;
            } catch (error) {
                this.sessions = []
                console.error('Error fetching session data:', error)
            }
        },

        async getPatientData() {
            const patientUuid = this.$route.params.uuid
            try {
                const patientData = await getCurrentPatient(patientUuid)
                this.patientData = patientData.data
                console.log(this.patientData);
            } catch (error) {
                console.error('Error fetching patient data:', error)
            }
        },

        formatDate(date) {
            return new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
            })
        },

        getGradeColor(grade) {
          if (grade === 'None') {
            return {
              background: 'white',
              border: '1px solid black',
              backgroundImage: 'linear-gradient(-45deg, transparent 0%, transparent 45%, black 45%, black 55%, transparent 55%, transparent 100%)'
            };
          }
          const colors = {
            'normal': '#007bff',
            'Normal': '#007bff',
            'Grade_1': '#ffc107',
            'Grade_2': '#dc3545'
          };
          return { background: colors[grade] || 'white' };
        }

    }
}
</script>

<template>

  <TitleHeader :title="`Patient Sessions`" />

  <div class="container">
    <div v-if="sessions && sessions.length > 0" class="sessions-data">
      <div class="header-section">
        <h2><i class="fas fa-folder-open"></i> Patient Sessions</h2>
        <div class="filter-section">
          <select v-model="selectedDate" class="form-select">
            <option value="">All Dates</option>
            <option v-for="date in availableDates" :key="date" :value="date">
              {{ formatDate(date) }}
            </option>
          </select>
        </div>
      </div>

      <div class="patient-card">
        <div class="patient-header">
          <i class="fas fa-user-circle"></i>
          <h3>Patient Information</h3>
        </div>
        
        <div class="patient-details-grid">
          <div class="detail-item">
            <span class="detail-label">Name</span>
            <span class="detail-value">{{ patientData.PatientName }}</span>
          </div>
          <div class="detail-item">
            <span class="detail-label">Age</span>
            <span class="detail-value">{{ patientData.PatientAgeAtScreening }} years</span>
          </div>
          <div class="detail-item">
            <span class="detail-label">Date of Birth</span>
            <span class="detail-value">{{ new Date(patientData.PatientDOB).toLocaleDateString() }}</span>
          </div>
          <div class="detail-item">
            <span class="detail-label">Diabetes Type</span>
            <span class="detail-value">{{ patientData.TypeOfDiabetes || 'None' }}</span>
          </div>
        </div>
      </div>

      <!-- <div class="sessions-list">
        <div v-for="session in sortedFilteredSessions" 
             :key="session.SessionUuid" 
             class="session-item">
          
          <div class="session-header">
            <div class="session-header-left">
              <div class="date-badge">
                <i class="fas fa-calendar-alt"></i>
                {{ formatDate(session.SessionDate) }}
              </div>
              <span class="session-id">ID: {{ session.SessionUuid.slice(0,8) }}</span>
            </div>
            <div class="session-header-right">
              <span v-if="session.SessionNotes" class="notes-badge"@click="session.showNotes = !session.showNotes"style="cursor: pointer;">
                <i class="fas fa-comment-medical"></i> Notes
              </span>
              <router-link 
                :to="`/case/image/${$route.params.uuid}/${session.SessionUuid}`"
                class="view-images-btn">
                <i class="fas fa-images"></i>Images
              </router-link>
              <button class="toggle-summary-btn text-black" @click="session.showSummary = !session.showSummary">
                <i :class="['fas', session.showSummary ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
              </button>
            </div>
          </div>

          <div v-if="session.SessionNotes && session.showNotes" class="session-notes m-2">
            {{ session.SessionNotes }}
          </div>

          <div class="session-summary" v-show="session.showSummary">
            <div class="summary-cards">
              <div class="summary-card">
                <div class="eye-title">
                  <i class="fas fa-eye"></i>
                  <span>Left Eye</span>
                </div>
                <div class="summary-content">
                  <div class="stat-row">
                    <span class="label">Abnormality:</span>
                    <span class="value" :class="session.summary.EyeType0.highest_abnormality">
                      {{ session.summary.EyeType0.highest_abnormality }}
                    </span>
                  </div>
                  <div class="stat-row">
                    <span class="label">Grading:</span>
                    <span class="value">{{ session.summary.EyeType0.highest_grading.replace('_', ' ') }}</span>
                  </div>
                  <div class="stat-row">
                    <span class="label">
                      Confidence:
                      <i class="fas fa-info-circle tooltip-icon">
                        <span class="tooltip-text">Average confidence from cases submitted</span>
                      </i>
                    </span>
                    <span class="value">{{ (session.summary.EyeType0.average_confidence * 100).toFixed(1) }}%</span>
                  </div>
                  <div class="stat-row">
                    <span class="label">No of Images:</span>
                    <span class="value">{{ session.summary.EyeType0.case_count }}</span>
                  </div>
                </div>
              </div>

              <div class="summary-card">
                <div class="eye-title">
                  <i class="fas fa-eye"></i>
                  <span>Right Eye</span>
                </div>
                <div class="summary-content">
                  <div class="stat-row">
                    <span class="label">Abnormality:</span>
                    <span class="value" :class="session.summary.EyeType1.highest_abnormality">
                      {{ session.summary.EyeType1.highest_abnormality }}
                    </span>
                  </div>
                  <div class="stat-row">
                    <span class="label">Grading:</span>
                    <span class="value">{{ session.summary.EyeType1.highest_grading.replace('_', ' ') }}</span>
                  </div>
                  <div class="stat-row">
                    <span class="label">
                      Confidence:
                      <i class="fas fa-info-circle tooltip-icon">
                        <span class="tooltip-text">Average confidence from cases submitted</span>
                      </i>
                    </span>
                    <span class="value">{{ (session.summary.EyeType1.average_confidence * 100).toFixed(1) }}%</span>
                  </div>
                  <div class="stat-row">
                    <span class="label">Cases:</span>
                    <span class="value">{{ session.summary.EyeType1.case_count }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- Progress Comparison Card -->
      <div v-if="periodDates" class="comparison-card">
        <div class="card-header">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h5><i class="fas fa-chart-line me-2"></i>Retinal Monitoring</h5>
              <p class="text-muted mb-0">
                From {{ periodDates.firstDate }} to {{ periodDates.latestDate }}
              </p>
            </div>
            <router-link 
              :to="`/case/compare/${$route.params.uuid}`" 
              class="compare-btn"
            >
              <i class="fas fa-exchange-alt"></i>
              Compare All Sessions
            </router-link>
          </div>
        </div>

        
        <div class="progression-card">
          <div class="progression-wrapper">
            <div class="progression-container">
              <div class="eye-indicators">
                <div class="eye-label" v-if="hasEyeType0">
                  <i class="fas fa-eye me-2"></i>Left Eye
                </div>
                <div class="eye-label" v-if="hasEyeType1">
                  <i class="fas fa-eye me-2"></i>Right Eye
                </div>
                <div class="eye-label" v-if="hasEyeType2">
                  <i class="fas fa-eye me-2"></i>Unspecified Eye
                </div>
              </div>
              <div class="progression-grid">
                <div v-for="session in raw_sessions" :key="session.SessionUuid" class="progression-column">
                  <div class="date-label">{{ new Date(session.SessionDate).toLocaleDateString() }}</div>
                  <div class="eyes-container">
                    <div 
                      class="eye-square" 
                      v-if="hasEyeType0"
                      :style="getGradeColor(session.summary.EyeType0.highest_grading)"
                      :title="`Left Eye: ${session.summary.EyeType0.highest_grading.replace('_', ' ')}`"
                    ></div>
                    <div 
                      class="eye-square"
                      v-if="hasEyeType1"
                      :style="getGradeColor(session.summary.EyeType1.highest_grading)"
                      :title="`Right Eye: ${session.summary.EyeType1.highest_grading.replace('_', ' ')}`"
                    ></div>
                    <div 
                      class="eye-square"
                      v-if="hasEyeType2"
                      :style="getGradeColor(session.summary.EyeType2.highest_grading)"
                      :title="`Unspecifed Eye: ${session.summary.EyeType2.highest_grading.replace('_', ' ')}`"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      
      <div class="table-container">
        <table class="sessions-table">
          <thead>
            <tr>
              <th style="width: 20%; text-align: left;">Session Details</th>
              <th style="width: 25%; text-align: center;" v-if="hasEyeType0"><i class="fas fa-eye"></i> Left Eye</th>
              <th style="width: 25%; text-align: center;" v-if="hasEyeType1"><i class="fas fa-eye"></i> Right Eye</th>
              <th style="width: 25%; text-align: center;" v-if="hasEyeType2"><i class="fas fa-eye"></i> Unspecified Eye</th>
              <th style="width: 15%; text-align: center;"> <i class="fa-regular fa-comment"></i> Notes</th>
              <th style="width: 15%; text-align: center;">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="session in sortedFilteredSessions" :key="session.SessionUuid">
              <td class="session-info" style="text-align: left;">
                <div class="session-date">{{ formatDate(session.SessionDate) }}</div>
                <div class="session-id text-truncate">ID: {{ session.SessionUuid.slice(0,8) }}</div>
              </td>
              
              <td style="text-align: center;" v-if="hasEyeType0">
                <div class="eye-info">
                  <div class="grade-value">{{ session.summary.EyeType0.highest_grading.replace('_', ' ') }}</div>
                  <div class="abnormality" :class="session.summary.EyeType0.highest_abnormality">
                    {{ session.summary.EyeType0.highest_abnormality }}
                  </div>
                  <div class="confidence">{{ (session.summary.EyeType0.average_confidence * 100).toFixed(1) }}%</div>
                </div>
              </td>
      
              <td style="text-align: center;" v-if="hasEyeType1">
                <div class="eye-info">
                  <div class="grade-value">{{ session.summary.EyeType1.highest_grading.replace('_', ' ') }}</div>
                  <div class="abnormality" :class="session.summary.EyeType1.highest_abnormality">
                    {{ session.summary.EyeType1.highest_abnormality }}
                  </div>
                  <div class="confidence">{{ (session.summary.EyeType1.average_confidence * 100).toFixed(1) }}%</div>
                </div>
              </td>

              <td style="text-align: center;" v-if="hasEyeType2">
                <div class="eye-info">
                  <div class="grade-value">{{ session.summary.EyeType2.highest_grading.replace('_', ' ') }}</div>
                  <div class="abnormality" :class="session.summary.EyeType2.highest_abnormality">
                    {{ session.summary.EyeType2.highest_abnormality }}
                  </div>
                  <div class="confidence">{{ (session.summary.EyeType2.average_confidence * 100).toFixed(1) }}%</div>
                </div>
              </td>
      
              <td style="text-align: center;">
                <div v-if="session.SessionNotes"> {{ session.SessionNotes }} </div>
              </td>
      
              <td style="text-align: center;">
                <div class="action-buttons">
                  <router-link 
                    :to="`/case/image/${$route.params.uuid}/${session.SessionUuid}`"
                    class="view-btn">
                    <i class="fas fa-images"></i>
                  </router-link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>      

    </div>
    
    <div v-else-if="sessions && sessions.length === 0" class="no-sessions">
      <i class="fas fa-image-slash fa-3x"></i>
      <h3>No Image Sessions</h3>
      <p>There are no image sessions available for this patient.</p>
    </div>
    
    <div v-else class="loading">
      <div class="spinner-border text-primary"></div>
      <span>Loading sessions...</span>
    </div>
  </div>
</template>

<style scoped>
.case-container {
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
}

.header-section {
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  padding: 1.5rem;
  border-radius: 12px;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-section {
  width: 200px;
}

.session-item {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  margin-bottom: 1.5rem;
  overflow: hidden;
  transition: all 0.3s ease;
}

.session-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.session-header {
  background: #f8f9fa;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
}

.session-header-left, .session-header-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.date-badge {
  background: linear-gradient(135deg, var(--primary-color) 0%, #0B8F88 100%);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.session-id {
  font-family: monospace;
  background: #f1f3f5;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  color: #495057;
}

.notes-badge {
  background: #28a745;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.view-images-btn {
  background: linear-gradient(135deg, #007bff 0%, #0056b3 100%);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
}

.view-images-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  color: white;
}

.session-summary {
  padding: 1.5rem;
}

.summary-cards {
  display: flex;
  gap: 2rem;
  justify-content: center;
}

.summary-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  min-width: 400px;
  flex: 1;
}

.eye-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #2c3e50;
}

.stat-row {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px solid #eee;
}

.stat-row:last-child {
  border-bottom: none;
}

.label {
  color: #666;
  font-weight: 500;
}

.value {
  font-weight: 600;
}

.value.abnormal {
  color: #dc3545;
}

.value.normal {
  color: #28a745;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 3rem;
}

@media (max-width: 768px) {
  .header-section {
    flex-direction: column;
    gap: 1rem;
  }

  .filter-section {
    width: 100%;
  }

  .session-header {
    flex-direction: column;
    gap: 1rem;
    font-size: 14px;
  }

  .session-header-left, .session-header-right {
    width: 100%;
    justify-content: space-between;
  }

  .summary-cards {
    flex-direction: column;
  }

  .summary-card {
    min-width: 100%;
  }
}
</style>


<style scoped>
.no-sessions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 3rem;
  text-align: center;
  color: #6c757d;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.no-sessions i {
  color: #adb5bd;
}

.no-sessions h3 {
  margin: 0;
  color: #495057;
}

.no-sessions p {
  margin: 0;
}
</style>

<style scoped>
.patient-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  margin-bottom: 2rem;
  padding: 1.5rem;
}

.patient-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
}

.patient-header i {
  font-size: 1.5rem;
  color: var(--primary-color);
}

.patient-header h3 {
  margin: 0;
  color: #2c3e50;
}

.patient-details-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.detail-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.detail-label {
  font-size: 0.9rem;
  color: #666;
  font-weight: 500;
}

.detail-value {
  font-size: 1.1rem;
  color: #2c3e50;
  font-weight: 600;
}

@media (max-width: 768px) {
  .patient-details-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  
  .detail-item {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .detail-item:last-child {
    border-bottom: none;
  }
}

</style>


<style scoped>
.toggle-summary-btn {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
}

.toggle-summary-btn:hover {
  background: #e9ecef;
}

.session-summary {
  transition: all 0.3s ease;
}

.session-notes {
  margin-top: 1rem;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
  border-left: 4px solid #28a745;
  animation: fadeIn 0.3s ease;
}

.notes-badge:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(40, 167, 69, 0.2);
}

</style>

<!-- TOOLTIP -->
<style scoped>
.tooltip-icon {
  position: relative;
  color: var(--primary-color);
  margin-left: 4px;
  cursor: help;
}

.tooltip-text {
  visibility: hidden;
  position: fixed; /* Changed from absolute to fixed */
  transform: translate(-50%, -100%); /* Adjust positioning */
  background: #333;
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 0.8rem;
  font-family: 'Courier New', Courier, monospace !important;
  white-space: nowrap;
  z-index: 9999;
  opacity: 0;
  transition: opacity 0.2s ease;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
  pointer-events: none;
}

.tooltip-icon:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip-text::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

</style>

<style scoped>
.table-container {
  width: 100%;
  overflow-x: auto;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  margin-top: 2rem;
}

.sessions-table {
  width: 100%;
  min-width: 1000px;
  border-collapse: collapse;
}

.sessions-table th {
  background: #f8f9fa;
  padding: 1rem;
  font-weight: 600;
  color: #444;
  border-bottom: 2px solid #eee;
}

.sessions-table td {
  padding: 1rem;
  border-bottom: 1px solid #eee;
}

.session-info .session-date {
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 0.25rem;
}

.session-info .session-id {
  font-size: 0.875rem;
  color: #6c757d;
}

.eye-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}

.grade-value {
  font-weight: 600;
  color: #2c3e50;
}

.abnormality {
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.875rem;
}

.abnormality.normal {
  background: #f6ffed;
  color: #389e0d;
}

.abnormality.abnormal {
  background: #fff2f0;
  color: #cf1322;
}

.confidence {
  font-size: 0.875rem;
  color: #6c757d;
}

.notes-btn, .view-btn {
  padding: 0.5rem;
  border-radius: 6px;
  background: var(--primary-color);
  color: white;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
}

.notes-btn:hover, .view-btn:hover {
  background: var(--primary-hover);
  transform: translateY(-1px);
}

.notes-row {
  background: #f8f9fa;
}

.notes-content {
  padding: 1rem;
  color: #666;
  font-style: italic;
}

.action-buttons {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
}
</style>

<style scoped>
.table-container {
  width: 100%;
  overflow-x: auto;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  margin-top: 2rem;
}

.sessions-table {
  width: 100%;
  min-width: 1000px;
  border-collapse: collapse;
}

.sessions-table th {
  background: var(--primary-color);
  padding: 1rem;
  font-weight: 600;
  color: white;
  border-bottom: 2px solid #eee;
}

.sessions-table tr:nth-child(even) {
  background-color: #f0faf9;
}

.sessions-table tr:nth-child(odd) {
  background-color: #ffffff;
}

.sessions-table tr:hover {
  background-color: #e6f7f6;
  transition: background-color 0.2s ease;
}

.sessions-table td {
  padding: 1rem;
  border-bottom: 1px solid #eee;
}
</style>

<style scoped>
.comparison-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  margin: 2rem 0;
  padding: 1.5rem;
}

.card-header {
  border-bottom: 1px solid #eee;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
}

.comparison-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.eye-comparison {
  text-align: center;
}

.grade-comparison {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 1rem;
}

.initial-grade, .current-grade {
  padding: 0.5rem 1rem;
  border-radius: 8px;
  background: #f8f9fa;
  font-weight: 600;
}

.arrow-indicator {
  font-size: 1.5rem;
}

.legend {
  display: flex;
  gap: 1.5rem;
  margin: 0.5rem 0;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  color: #6c757d;
}

@media (max-width: 768px) {
  .comparison-grid {
    gap: 1.5rem;
  }
}

.compare-btn {
  padding: 8px 16px;
  background: var(--primary-color);
  color: white;
  border-radius: 6px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s;
}

.compare-btn:hover {
  background: var(--primary-hover);
  transform: translateY(-1px);
  color: white;
}


</style>

<style scoped>
.progression-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-x: auto;
}

.progression-container {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  max-width: fit-content;
}

.eye-indicators {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-right: 1rem;
  border-right: 1px solid #eee;
  padding-top: 32px; /* Reduced to account for horizontal date */
}

.eye-label {
  height: 32px;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #666;
  padding-right: 1rem;
}

.progression-grid {
  display: flex;
  gap: 1rem;
}

.progression-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 80px;
}

.date-label {
  font-size: 0.75rem;
  color: #666;
  margin-bottom: 0.5rem;
  height: 20px;
  text-align: center;
}

.eyes-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 120px;
  justify-content: space-between;
}

.eye-square {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  cursor: pointer;
  transition: transform 0.2s;
}

.eye-square:hover {
  transform: scale(1.2);
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
}
</style>