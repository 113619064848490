import axios from 'axios';
import { useAuthStore } from '@/stores/authStore';

const BACKEND_URL = 'https://drmatabackend-584749671098.asia-southeast1.run.app';

export const sendFeedback = async (payload, retryAttempt = false) => {
    const authStore = useAuthStore();

    try {
      const response = await axios.post(`${BACKEND_URL}/drmata_user/user_feedback`, payload, {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });
  
      return response.data; // Return response data upon successful registration
      
      } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return sendFeedback(payload, true); // Retry after refreshing
        }
        throw error;
    }
}