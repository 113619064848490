<template>
  <ImageViewer
      v-model:show="showImageViewer"
      :image="media_data"
      :width="800"
      :height="600"
  />

  <div class="overlay">
      <div class="audit-form-card">
          <div class="form-header">
            <h2>Submit Feedback</h2>
            <button class="close-button" @click="closeOverlay">
              <i class="fas fa-times"></i>
            </button>
          </div>

          <!-- Add image container here -->
          <div class="image-container">
              <div class="image-wrapper">
                <img :src="media_data.SignedUrl" alt="Audit Image" class="audit-image" @click="openImageViewer">
                <button class="zoom-button" @click="openImageViewer">
                  <i class="fas fa-search-plus"></i>
                </button>
              </div>
          </div>

          <div class="form-content">
              <div class="form-group">
                  <label>Abnormality Status <span class="required">*</span></label>
                  <select 
                      v-model="formData.FeedbackAbnormality" 
                      class="form-input"
                      :class="{ 'error': validationErrors.FeedbackAbnormality }"
                  >
                      <option value="">Select Status</option>
                      <option value="normal">Normal</option>
                      <option value="abnormal">Abnormal</option>
                      <option value="non retinal image">Non Retinal Image</option>
                  </select>
                  <span v-if="validationErrors.FeedbackAbnormality" class="error-message">
                      Please select abnormality status
                  </span>
              </div>

              <div class="form-group" v-if="formData.FeedbackAbnormality === 'abnormal'">
                  <label>Grade <span class="required">*</span></label>
                  <select 
                      v-model="formData.FeedbackGrade" 
                      class="form-input"
                      :class="{ 'error': validationErrors.FeedbackGrade }"
                  >
                      <option value="">Select Grade</option>
                      <option value="grade_1">Grade 1</option>
                      <option value="grade_2">Grade 2</option>
                  </select>
                  <span v-if="validationErrors.FeedbackGrade" class="error-message">
                      Please select an audit grade
                  </span>
              </div>

              <div class="form-group">
                  <label>Comments</label>
                  <textarea 
                  v-model="formData.FeedbackComments" 
                  class="form-input textarea"
                  placeholder="Enter your comments here..."
                  rows="4"
                  ></textarea>
              </div>
          
              <div class="form-actions">
                  <button class="submit-button" @click="handleSubmit">
                  Submit Feedback
                  </button>
              </div>
          </div>

      </div>
  </div>
</template>

<script>
import { sendFeedback } from '@/services/feedbackServices'
import ImageViewer from '@/components/ImageViewer.vue'

export default {
  components: {
      ImageViewer
  },

props: {
  media_data: {
      type: Object,
      required: true
  }
},

data() {
  return {
  formData: {
      MediaUuid: this.media_data.MediaUuid,
      FeedbackOutcome: this.media_data.Outcome,
      FeedbackGrade: '',
      FeedbackAbnormality: '',
      FeedbackComments: ''
  },
  validationErrors: {
      FeedbackGrade: false,
      FeedbackAbnormality: false
  },
  showImageViewer: false,
  isEditting: false
  }
},

methods: {
  validateForm() {
      this.validationErrors = {
          FeedbackGrade: !this.formData.FeedbackGrade,
          FeedbackAbnormality: !this.formData.FeedbackAbnormality
      }
      return !Object.values(this.validationErrors).some(error => error)
  },

  async handleSubmit() {

      switch(this.formData.FeedbackAbnormality){
          case 'normal':
              this.formData.FeedbackGrade = 'normal';
          break;
          case 'non retinal image':
              this.formData.FeedbackGrade = 'non retinal image';
          break;
      }
      
      console.log(this.formData);

      if (!this.validateForm()) {
          return
      }
      try {
          await sendFeedback(this.formData)
          this.$emit('audit-submitted')
          this.closeOverlay()
          this.resetFormData()
          alert('Audit submitted successfully')
      } catch (error) {
          console.error('Error submitting audit:', error)
          alert('Error submitting audit: '+ error.message)
      }
  },

  resetFormData(){
      this.formData = {
          MediaUuid: this.media_data.MediaUuid,
          FeedbackOutcome: '',
          FeedbackGrade: '',
          FeedbackAbnormality: '',
          FeedbackComments: ''
      }
  },

  closeOverlay() {
    this.$emit('close')
  },

  openImageViewer() {
    this.showImageViewer = true
    console.log('image: ' + this.showImageViewer)
  },
}
}
</script>

<style scoped>
.required {
color: #ef4444;
margin-left: 4px;
}

.error {
border-color: #ef4444;
}

.error-message {
color: #ef4444;
font-size: 0.875rem;
margin-top: 0.25rem;
}

.overlay {
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
background: rgba(0, 0, 0, 0.75);
display: flex;
align-items: center;
justify-content: center;
z-index: 1050;
}

.image-container {
width: 100%;
margin-bottom: 1.5rem;
display: flex;
justify-content: center;
align-items: center;
}

.audit-image {
max-width: 100%;
max-height: 300px;
object-fit: contain;
border-radius: 8px;
}

.audit-form-card {
background: white;
border-radius: 12px;
width: 95%;
max-width: 600px;
padding: 1.5rem;
max-height: 90vh;
overflow-y: auto;
}

.form-header {
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 1.5rem;
}

.form-header h2 {
color: #1e293b;
font-size: 1.25rem;
font-weight: 600;
}

.close-button {
background: none;
border: none;
color: #64748b;
cursor: pointer;
font-size: 1.25rem;
}

.form-content {
display: flex;
flex-direction: column;
gap: 1.5rem;
}

.form-group {
display: flex;
flex-direction: column;
gap: 0.5rem;
}

.form-group label {
color: #475569;
font-weight: 500;
font-size: 0.875rem;
}

.form-input {
padding: 0.75rem;
border: 1px solid #e2e8f0;
border-radius: 6px;
font-size: 0.95rem;
transition: all 0.2s;
}

.form-input:focus {
outline: none;
border-color: #3b82f6;
box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.textarea {
resize: vertical;
min-height: 100px;
}

.form-actions {
margin-top: 2rem;
display: flex;
justify-content: flex-end;
}

.submit-button {
background: var(--primary-color);
color: white;
padding: 0.75rem 2rem;
border: none;
border-radius: 6px;
font-weight: 500;
cursor: pointer;
transition: all 0.2s;
}

.submit-button:hover {
background: var(--primary-hover);
transform: translateY(-1px);
}

.cancel-button {
background: #dc362e;
color: white;
padding: 0.75rem 2rem;
border: none;
border-radius: 6px;
font-weight: 500;
cursor: pointer;
transition: all 0.2s;
}

.cancel-button:hover {
background: #410e0b;
transform: translateY(-1px);
}
</style>

<style scoped>
.image-wrapper {
position: relative;
display: inline-block;
}

.zoom-button {
position: absolute;
bottom: 10px;
right: 10px;
background: rgba(0, 0, 0, 0.5);
color: white;
border: none;
border-radius: 50%;
width: 36px;
height: 36px;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
transition: background 0.2s;
}

.zoom-button:hover {
background: rgba(0, 0, 0, 0.7);
}

.audit-image {
cursor: pointer;
}
</style>