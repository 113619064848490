<template>

  <div v-if="show" class="image-overlay">
    <transition name="loadfade">
      <Loading  v-if="isLoading" style="z-index: 5;" />
    </transition>

    <div class="overlay-content">
      <button class="close-btn" style="z-index: 6;" @click="closeViewer">
        <i class="fas fa-times"></i>
      </button>

      <div class="image-container">
        <img 
          :src="image.SignedUrl" 
          :style="{ 
            filter: `brightness(${brightness}%) contrast(${contrast}%)`,
            width: width ? `${width}px` : 'auto',
            height: height ? `${height}px` : 'auto'
          }"
          alt="Full size image" 
        />

        <img v-if="showAVR" 
         :src="AVR_URL" 
         :style="{ 
            filter: `brightness(${brightness}%) contrast(${contrast}%)`,
            width: width ? `${width}px` : 'auto',
            height: height ? `${height}px` : 'auto'
          }"
         class="overlay-image avr-overlay" />

         <img v-if="showVCDR" 
         :src="VCDR_URL" 
         :style="{ 
            filter: `brightness(${brightness}%) contrast(${contrast}%)`,
          }"
         class="overlay-image vcdr-overlay" />
      </div>

      <div class="image-controls" style="z-index: 6;">
        <div class="control-group">
          <span class="control-label">Brightness</span>
          <input type="range" v-model="brightness" min="0" max="200" step="10">
          <span class="control-value">{{brightness}}%</span>
        </div>
        <div class="control-group">
          <span class="control-label">Contrast</span>
          <input type="range" v-model="contrast" min="0" max="200" step="10">
          <span class="control-value">{{contrast}}%</span>
        </div>
        <div class="control-group">
          <span class="control-label">AVR</span>
          <label class="toggle-switch">
            <input type="checkbox" v-model="showAVR">
            <span class="toggle-slider"></span>
          </label>
        </div>
        <div class="control-group" v-if="patientUuid && sessionUuid">
          <span class="control-label">VCDR</span>
          <label class="toggle-switch">
            <input type="checkbox" v-model="showVCDR">
            <span class="toggle-slider"></span>
          </label>
        </div>

        <div class="control-group" v-if="showVCDR && VCDR_value">
          <span class="control-label">VCDR Value</span>
          <span class="control-label">{{ Number(VCDR_value).toFixed(2) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { rww_segment, vcdr_process } from '@/services/imageProcessServices';
import Loading from './Loading.vue';

export default {
name: 'ImageViewer',
components: {
  Loading
},
props: {
  show: {
    type: Boolean,
    default: false
  },
  image: {
    type: Object,
    required: true
  },
  width: {
    type: Number,
    default: null
  },
  height: {
    type: Number,
    default: null
  },
  patientUuid: {
    type: String,
  },
  sessionUuid: {
    type: String,
  },
},
data() {
  return {
    brightness: 100,
    contrast: 100,
    showAVR: false,
    AVR_URL: '',
    showVCDR: false,
    VCDR_URL: '',
    VCDR_value: '',
    processedImageUrl: null,
    isLoading: false,
  }
},
watch: {
  async showAVR(newValue) {
    if (newValue) {
      try {
        if(!this.AVR_URL){
          this.isLoading = true
          const response = await rww_segment(this.image.MediaUuid)
          console.log(response);
          this.AVR_URL = response.image_url ? response.image_url : ''
          this.VCDR_value = response.vcdr ? response.vcdr : ''
          this.isLoading = false
          console.log(response)
        }
      } catch (error) {
        this.isLoading = false
        alert('Error processing AVR');
        console.error('Error processing AVR:', error);
      }
    }
  },
  async showVCDR(newValue) {
    if (newValue) {
      try {
        if(!this.VCDR_URL){
          if(this.patientUuid && this.sessionUuid){
            this.isLoading = true
            const response = await vcdr_process(this.image.MediaUuid, this.patientUuid, this.sessionUuid) //(MediaUuid, PatientUuid, SessionUuid)
            console.log(response);
            this.VCDR_URL = response.image_url ? response.image_url : ''
            this.VCDR_value = response.vcdr ? response.vcdr : ''
            this.isLoading = false
            console.log(response)
          }
        }
      } catch (error) {
        this.isLoading = false
        alert('Error processing VCDR');
        console.error('Error processing VCDR_URL:', error);
      }
    }
  }
},
mounted() {
  window.addEventListener('keydown', this.handleKeyDown)
},
beforeDestroy() {
  window.removeEventListener('keydown', this.handleKeyDown)
  this.reset()
},
methods: {
  closeViewer() {
    this.reset()
    this.$emit('update:show', false)
  },
  reset(){
    this.AVR_URL = ''
    this.VCDR_URL = ''
    this.showAVR = false
    this.showVCDR = false
    this.brightness = 100
    this.contrast = 100
  },
  handleKeyDown(event) {
    if (event.key === 'Escape' && this.show) {
      this.closeViewer()
    }
  },

}
}
</script>

  
  <style scoped>
  .image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .overlay-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 90vw;
    max-height: 90vh;
  }
  
  .overlay-content img {
    max-width: 100%;
    max-height: 90vh;
    object-fit: contain;
  }
  
  .close-btn {
    position: absolute;
    top: -40px;
    right: 0;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    padding: 8px;
  }
  
  .close-btn:hover {
    color: #ff4444;
  }
  
  .image-controls {
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 2rem;
    background: rgba(0, 0, 0, 0.226);
    padding: 1rem 2rem;
    border-radius: 8px;
  }
  
  .control-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
  
  .control-label {
    color: white;
    font-size: 0.9rem;
    font-weight: 500;
  }
  
  .control-value {
    color: white;
    font-size: 0.8rem;
  }
  
  input[type="range"] {
    width: 150px;
    cursor: pointer;
  }

  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
  }
  
  .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 24px;
  }
  
  .toggle-slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .toggle-slider {
    background-color: #2196F3;
  }
  
  input:checked + .toggle-slider:before {
    transform: translateX(26px);
  }
  </style>

  <style scoped>
  .image-container {
    position: relative;
    width: fit-content;
    max-width: 100%;
  }
  
  .base-image {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .overlay-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .avr-overlay {
    opacity: 1;
    z-index: 1;
    object-fit: cover;
  }
  
  .vcdr-overlay {
    opacity: 1;
    z-index: 2;
    object-fit: contain; /* This preserves aspect ratio while fitting within container */
    box-sizing: border-box;
  }

  @media screen and (max-width: 768px) {
    .image-container {
      width: 100%;
    }
    
    .vcdr-overlay {
      object-position: center;
    }

    .overlay-content {
    padding-bottom: 200px; /* Add space for controls */
  }

    .image-controls {
      flex-direction: column;
      gap: 1rem;
      width: 90%;
      bottom: 20px; /* Bring controls up */
      background: rgba(0, 0, 0, 0.8); /* Slightly darker background for better visibility */
    }

    .control-group {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    input[type="range"] {
      width: 40%;
    }

    .control-label {
      width: 80px;
    }
}

.loadfade-enter-active,
.loadfade-leave-active {
    transition: opacity 0.3s
}

.loadfade-enter-from,
.loadfade-leave-to {
    opacity: 0
}

</style>