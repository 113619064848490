import axios from 'axios';
import { useAuthStore } from '@/stores/authStore';

const BACKEND_URL = 'https://drmatabackend-584749671098.asia-southeast1.run.app';

export const rww_segment = async (MediaUuid) => {
  const authStore = useAuthStore();
  
  // Create URLSearchParams for form-urlencoded data
  const formData = new URLSearchParams();
  formData.append('MediaUuid', MediaUuid);
  
  try {
      const response = await axios.post(
          `${BACKEND_URL}/drmata_user/model/rww_segment`,
          formData,
          {
              headers: {
                  'accept': 'application/json',
                  'Authorization': `Bearer ${authStore.accessToken}`,
                  'Content-Type': 'application/x-www-form-urlencoded'
              }
          }
      );
      return response.data;
  } catch (error) {
      throw error;
  }
};

export const vcdr_process = async (MediaUuid, PatientUuid, SessionUuid) => {
  const authStore = useAuthStore();
  
  const formData = new URLSearchParams();
  formData.append('MediaUuid', MediaUuid);
  formData.append('PatientUuid', PatientUuid);
  formData.append('SessionUuid', SessionUuid);
  
  try {
      const response = await axios.post(
          `${BACKEND_URL}/drmata_user/model/vcdr`,
          formData,
          {
              headers: {
                  'accept': 'application/json',
                  'Authorization': `Bearer ${authStore.accessToken}`,
                  'Content-Type': 'application/x-www-form-urlencoded'
              }
          }
      );
      return response.data;
  } catch (error) {
      throw error;
  }
};

