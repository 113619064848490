
<template>
    <div class="container mt-5">
        <div class="patient-card">
            <div class="patient-header">
              <i class="fas fa-user-circle"></i>
              <h3>Patient Information</h3>
            </div>
            
            <div class="patient-details-grid">
              <div class="detail-item">
                <span class="detail-label">Name</span>
                <span class="detail-value">{{ patient_data.PatientName }}</span>
              </div>
              <div class="detail-item">
                <span class="detail-label">Age</span>
                <span class="detail-value">{{ patient_data.PatientAgeAtScreening }} years</span>
              </div>
              <div class="detail-item">
                <span class="detail-label">Date of Birth</span>
                <span class="detail-value">{{ new Date(patient_data.PatientDOB).toLocaleDateString() }}</span>
              </div>
              <div class="detail-item">
                <span class="detail-label">Diabetes Type</span>
                <span class="detail-value">{{ patient_data.TypeOfDiabetes || 'None' }}</span>
              </div>
            </div>
          </div>
    </div>

    <div class="container">
        <div class="referral-form-card">
            <div class="form-header">
              <i class="fas fa-file-medical"></i>
              <h3>Referral Details</h3>
            </div>
          
            <div class="form-grid">
              <div class="form-group">
                <label>Refer To Hospital</label>
                <div class="disabled form-input">{{ referralData.ReferToHospitalName ? referralData.ReferToHospitalName : '-' }}</div>
              </div>
          
              <div class="form-group">
                <label>Reason for Referral/Remarks</label>
                <div class="disabled form-input">{{ referralData.Reason ? referralData.Reason : '-' }}</div>
              </div>
          
              <div class="form-group checkbox-group">
                <label class="checkbox-label">
                  <input v-model="referralData.HasMaculopathy" type="checkbox" disabled/>
                  Has Maculopathy
                </label>
              </div>
          
              <div class="form-group" v-if="referralData.HasMaculopathy">
                <label>Maculopathy Eye</label>
                <select v-model="referralData.MaculopathyEye" class="form-input" disabled>
                  <option value="Left">Left Eye</option>
                  <option value="Right">Right Eye</option>
                  <option value="Both">Both Eyes</option>
                </select>
              </div>
          
              <div class="eye-section">
                <h4>Right Eye Details</h4>
                <div class="eye-grid">
                  <div class="form-group">
                    <label>Clinical Grade</label>
                    <div class="disabled form-input">{{ referralData.ClinicalGradeRightEye ? referralData.ClinicalGradeRightEye : '-' }}</div>
                  </div>
                  <div class="form-group">
                    <label>Other Findings</label>
                    <div class="disabled form-input">{{ referralData.OtherFindingsRightEye ? referralData.OtherFindingsRightEye : '-' }}</div>
                  </div>
                  <div class="form-group">
                    <label>AI Grade</label>
                    <div class="disabled form-input">{{ referralData.AIGradeRightEye.replace(/_/g, ' ') }}</div>
                  </div>
                  <div class="form-group">
                    <label>Confidence Level</label>
                    <div class="disabled form-input">{{String(Math.round(this.referralData.ConfidenceLevelRightEye * 100) + "%")}}</div>
                  </div>
                </div>
              </div>
          
              <div class="eye-section">
                <h4>Left Eye Details</h4>
                <div class="eye-grid">
                  <div class="form-group">
                    <label>Clinical Grade</label>
                    <div class="disabled form-input">{{ referralData.ClinicalGradeLeftEye ? referralData.ClinicalGradeLeftEye : '-' }}</div>
                  </div>
                  <div class="form-group">
                    <label>Other Findings</label>
                    <div class="disabled form-input">{{ referralData.OtherFindingsLeftEye ? referralData.OtherFindingsLeftEye : '-' }}</div>
                  </div>
                  <div class="form-group">
                    <label>AI Grade</label>
                    <div class="disabled form-input">{{ referralData.AIGradeLeftEye.replace(/_/g, ' ') }}</div>
                  </div>
                  <div class="form-group">
                    <label>Confidence Level</label>
                    <div class="disabled form-input">{{String(Math.round(this.referralData.ConfidenceLevelLeftEye * 100) + "%")}}</div>
                  </div>
                </div>
              </div>
          
              <div class="form-group">
                <label>Other Comments</label>
                <textarea v-model="referralData.OtherComments" class="form-input" disabled></textarea>
              </div>
            </div>
        </div>
    </div>

        <div class="container" v-if="alert">
          <div :class="['alert', alertType]">
            <span class="mx-auto">{{ alertMessage }} test</span>
          </div>
        </div>
    

    <div class="container d-flex justify-content-center mb-2">
      <button type="submit" @click="generatePDF" class="mx-2"><i class="fa fa-eye"></i> Preview</button>
      <button type="submit" @click="printPDF" class="mx-2"><i class="fa-solid fa-print"></i> Print</button>
      <button type="submit" @click="downloadPDF" class="mx-2"><i class="fa fa-download"></i> Download</button>
    </div>

    <PDFViewer
        v-model:isOpen="testviewer"
        :source="pdfUrl"
      />
</template>

<script>
import { jsPDF } from 'jspdf'
import html2pdf from 'html2pdf.js';
import { getCurrentPatient } from '@/services/patientServices'
import { getSessionImage } from '@/services/sessionServices'
import KKMLogo from '@/assets/KKM/Logo_KKM.jpeg'
import DrMATALogo from '@/assets/DrMata/LOGO-DRMATA-Black.png'
import { getReferral } from '@/services/referralServices'
import { getUser } from '@/services/userServices'

import PDFViewer from '@/pages/Referral/PDFViewer.vue'

export default {
  name: 'Referral',
  components: {
        PDFViewer
    },
  data() {
    return {
      user_data: null,
      pdfUrl: null,
      patient_data: [],
      isFullscreen: false,
      patientUUID: '',
      sessionUUID: '',
      referralUUID: this.$route.params.referralUuid,
      referralData: {
        PatientUuid: "",
        PatientName: "",
        SessionUuid: "",
        ReferToHospitalName: "",
        ReferToHospitalUUID: "",
        Reason: "",
        HasMaculopathy: false,
        MaculopathyEye: "",
        OtherComments: "",
        ClinicalGradeRightEye: "",
        OtherFindingsRightEye: "",
        AIGradeRightEye: "",
        ConfidenceLevelRightEye: 0,
        ClinicalGradeLeftEye: "",
        OtherFindingsLeftEye: "",
        AIGradeLeftEye: "",
        ConfidenceLevelLeftEye: 0
      },
      testviewer : false,
      alert: false,
      alertMessage: '',
      alertType: '',
    }
  },
  mounted() {
    this.get_all_data()
    this.get_user_data()
},
  methods: {
    async get_all_data(){
      await this.get_referral_data();
      if(this.patientUUID && this.sessionUUID){
        this.get_patient_data();
        this.get_session_data();
      }
    },

    async get_user_data(){
      const user = await getUser();
      this.user_data = user.user_info;
    },
    async get_referral_data(){
        const referral_data = await getReferral(this.referralUUID);
        this.referralData = referral_data.data;

        this.patientUUID = this.referralData.PatientUuid
        this.sessionUUID = this.referralData.SessionUuid
    },
    async get_patient_data(){
        const patient_data = await getCurrentPatient(this.patientUUID);
        this.patient_data = patient_data.data;
    },
    async get_session_data(){
        const session_data = await getSessionImage(this.sessionUUID);
        this.session_data = session_data;

        this.referralData.AIGradeLeftEye = session_data.highestLeftEyeGrading
        this.referralData.ConfidenceLevelLeftEye = session_data.averageLeftEyeConfident
        
        this.referralData.AIGradeRightEye = session_data.highestRightEyeGrading
        this.referralData.ConfidenceLevelRightEye = session_data.averageRightEyeConfident

        console.log(this.referralData)
        console.log("session_data", this.session_data);
    },
    async generatePDF() {
      const htmlContent = this.htmlstring();

      const element = document.createElement('div');
      element.innerHTML = htmlContent;

      const opt = {
        margin: 1,
        filename: 'referral.pdf',
        image: { type: 'jpeg', quality: 1 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
      };

      try{
        const pdfBlob = await html2pdf().set(opt).from(element).output('blob');
        this.pdfUrl = URL.createObjectURL(pdfBlob);
        this.testviewer = true;
      }
      catch(error){
        console.log(error)
      }
    },

    async printPDF() {
          try {

            const htmlContent = this.htmlstring();

            const element = document.createElement('div');
            element.innerHTML = htmlContent;

            const opt = {
              margin: 1,
              filename: 'referral.pdf',
              image: { type: 'jpeg', quality: 1 },
              html2canvas: { scale: 2 },
              jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
            };

            try{
            const pdfBlob = await html2pdf().set(opt).from(element).output('blob');
            this.pdfUrl = URL.createObjectURL(pdfBlob);
            }
            catch(error){
              console.log(error)
            }

            const printWindow = window.open(this.pdfUrl);
            printWindow.onload = function() {
              printWindow.print();
            };
          } catch (error) {
            console.error('Error printing PDF:', error);
          }
      },
    

    hasOtherFindings() {
      return this.referralData.OtherFindingsRightEye || this.referralData.OtherFindingsLeftEye;
    },

    htmlstring() {
      const otherColumnHeader = this.hasOtherFindings() ? '<th>Remark</th>' : '';
      const otherColumnRight = this.hasOtherFindings() ? `<td>${this.referralData.OtherFindingsRightEye || '-'}</td>` : '';
      const otherColumnLeft = this.hasOtherFindings() ? `<td>${this.referralData.OtherFindingsLeftEye || '-'}</td>` : '';


      const string = `<!DOCTYPE html>
        <html>
        <head>
            <style>
                body {
                    font-family: Arial, sans-serif;
                    font-size: 12px;
                    max-width: 800px;
                    margin: 0 auto;
                    padding: 20px;
                    line-height: 1.6;
                    position: relative;
                    min-height: 100vh; // Add this
                }

                .bottom-details {
                    position: absolute;
                    bottom: 20px;
                    left: 20px;
                    right: 20px;
                    width: calc(100% - 40px);
                }

                .main-content {
                    max-height: 100px;
                    overflow-y: auto;
                }

                .watermark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                    opacity: 0.1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    pointer-events: none;
                }
                .watermark img {
                    width: 80%;
                    max-width: 600px;
                }
                .header-logo {
                    text-align: center;
                }
                .header-logo img {
                    width: 280px;
                }
                .title {
                    text-align: center;
                    font-weight: bold;
                }
                .meta-info {
                    margin-bottom: 20px;
                }
                .patient-info {
                    margin-bottom: 10px;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                    margin: 20px 0;
                }
                th, td {
                    border: 1px solid black;
                    padding: 8px;
                    text-align: center;
                }
                .footer-logo {
                    text-align: center;
                    margin-top: 20px;
                }
                .footer-logo img {
                    width: 150px;
                }
                .grading-info {
                    margin-top: 100px;
                    font-size: 0.9em;
                }
                .signature {
                    bottom: 500px;
                }
                .red-text {
                    color: red;
                }
            </style>
        </head>
        <body>
            <div class="watermark">
                <img src="${DrMATALogo}" alt="DR-MATA Watermark">
            </div>

            <div class="header-logo">
                <img src="${KKMLogo}" alt="Ministry of Health Malaysia Logo">
            </div>

            <div class="title">Referral Letter</div>

            <div class="meta-info">
                09/12/2024
            </div>

            <div class="patient-info">
                RE: <span>${this.patient_data.PatientName} ${this.patient_data.ICNumber}</span>
            </div>

            <div>Dear Ophthalmologist,</div>

            <div class="patient-info">
                With reference to the above-mentioned patient, we are referring this patient for expert opinion and management.<br>
                Patient has been detected to have the following condition based on our screening:
            </div>

            <table>
                <tr>
                    <th>Eye Category</th>
                    <th>Clinical Grading</th>
                    <th>AI Grading</th>
                    ${otherColumnHeader}
                </tr>
                <tr>
                    <td>Right</td>
                    <td>${this.referralData.ClinicalGradeRightEye ? this.referralData.ClinicalGradeRightEye : '-'}</td>
                    <td>${this.referralData.AIGradeRightEye.replace(/_/g, ' ')}</td>
                    ${otherColumnRight}
                </tr>
                <tr>
                    <td>Left</td>
                    <td>${this.referralData.ClinicalGradeRightEye ? this.referralData.ClinicalGradeLeftEye : '-'}</td>
                    <td>${this.referralData.AIGradeLeftEye.replace(/_/g, ' ')}</td>
                    ${otherColumnLeft}
                </tr>
            </table>

            <div class="main-content" style="margin-bottom: 400px;">
                <div>
                    ${ this.referralData.Reason ? 'Reason : '+this.referralData.Reason+'<br>' : ''}
                    ${ this.referralData.OtherComments ? this.referralData.OtherComments+'<br>' : ''}
                </div>
            </div>

            <div class="bottom-details">
                <div class="signature">
                    Regards,<br>
                    <span>${this.user_data.FullName}</span><br>
                    Medical Officer<br>
                    ${ this.referralData.ReferToHospitalName }
                </div>

                <div class="grading-info">
                    Grade 0 (Normal): Repeat fundus photo within a year<br>
                    Grade 1 (Mild to Moderate NPDR): Repeat fundus photo within 6 months<br>
                    Grade 2 (Severe NPDR/PDR/ADED/Maculopathy): To refer ophthalmologist
                </div>

                <div class="footer-logo">
                    <img src="${DrMATALogo}" alt="DR-MATA Logo">
                </div>
            </div>
        </body>
        </html>`

      return string
    },


    async downloadPDF() {
      const htmlContent = this.htmlstring();
      const element = document.createElement('div');
      element.innerHTML = htmlContent;

      const opt = {
        margin: 1,
        filename: `Referral_${this.referralData.PatientName}_${new Date().toLocaleDateString()}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
      };

      await html2pdf()
        .set(opt)
        .from(element)
        .save();
    }  

      
  }
}
</script>

<style scoped>
.patient-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  margin-bottom: 2rem;
  padding: 1.5rem;
}

.patient-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
}

.patient-header i {
  font-size: 1.5rem;
  color: var(--primary-color);
}

.patient-header h3 {
  margin: 0;
  color: #2c3e50;
}

.patient-details-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.detail-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.detail-label {
  font-size: 0.9rem;
  color: #666;
  font-weight: 500;
}

.detail-value {
  font-size: 1.1rem;
  color: #2c3e50;
  font-weight: 600;
}

@media (max-width: 768px) {
  .patient-details-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  
  .detail-item {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .detail-item:last-child {
    border-bottom: none;
  }
}

</style>

<!-- REFERRAL FORM -->
<style scoped>
.referral-form-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  margin: 2rem auto;
  padding: 1.5rem;
}

.form-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
}

.form-header i {
  font-size: 1.5rem;
  color: var(--primary-color);
}

.form-header h3 {
  margin: 0;
  color: #2c3e50;
}

.form-grid {
  display: grid;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-input {
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s;
}

.form-input:focus {
  border-color: var(--primary-color);
  outline: none;
}

.checkbox-group {
  flex-direction: row;
  align-items: center;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.eye-section {
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
}

.eye-section h4 {
  color: #2c3e50;
  margin-bottom: 1rem;
}

.eye-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

textarea.form-input {
  min-height: 100px;
  resize: vertical;
}

label {
  font-weight: 500;
  color: #2c3e50;
}

@media (max-width: 768px) {
  .eye-grid {
    grid-template-columns: 1fr;
  }
}
</style>

<style>
button {
  background: var(--primary-color);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

button:hover {
  background: var(--primary-hover);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.15);
}

button:active {
  transform: translateY(0);
}
</style>

<style scoped>
    .alert {
      padding: 15px;
      margin: 10px 0;
      border-radius: 4px;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .alert-success {
      background-color: #d4edda;
      color: #155724;
      border: 1px solid #c3e6cb;
    }

    .alert-error {
      background-color: #f8d7da;
      color: #721c24;
      border: 1px solid #f5c6cb;
    }

    .close-btn {
      background: none;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
      padding: 0 5px;
    }
</style>