import axios from 'axios';
import { useAuthStore } from '../stores/authStore';

const BACKEND_URL = "https://drmatabackend-584749671098.asia-southeast1.run.app"

export const getUser = async (retryAttempt = false) => {
    const authStore = useAuthStore();

    try {
        const response = await axios.get(`${BACKEND_URL}/nora_user/details`, {
        headers: {
            Authorization: `Bearer ${authStore.accessToken}`,
        },
        });
    
        return response.data;
    } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return getUser(true); // Retry after refreshing
        }
        throw error;
    }
}

export const editUser = async (payload, retryAttempt = false) => {
    const authStore = useAuthStore();
    try {
        const response = await axios.put(`https://langserve-copilot-dp7nm56cca-as.a.run.app/nora_user/update_details`, payload ,{
        headers: {
            'X-Qmed-Host': 'app.sandbox.qmed.asia',
            'X-Qmed-Token': '19742f5e-030b-4f27-a5a0-8b8e6fab7dcd',
            Authorization: `Bearer ${authStore.accessToken}`,
          },
      });
  
      return response.data;
    }
    catch (error) {
      if (error.response?.status === 401 && !retryAttempt) {
        // Token might be expired, attempt to refresh
        await authStore.refreshAccessToken();
        return editUser(payload, true); // Retry after refreshing
      }
      throw error;
    }
  
  }