<template>

  <TitleHeader :title="`Referral List`">
    <template #icon>
      <FileTextIcon />
    </template>
  </TitleHeader>

  <div class="referral-container">

    <SearchBar
    searchPlaceholder="Search Referral Here.."
    buttonPlaceholder="Search"
    @OutputSearch="get_search_referral"
    class="mb-4"
    />

    <div class="table-container">
      <table class="referral-table">
        <thead>
          <tr>
            <th style="width: 25%; text-align: left;"><i class="fa-solid fa-hospital-user"></i> Patient Details</th>
            <th style="width: 25%; text-align: center;"><i class="fa-regular fa-eye"></i> Eye Grades</th>
            <th style="width: 15%; text-align: center;">Priority</th>
            <th style="width: 15%; text-align: center;">Status</th>
            <th style="width: 12%; text-align: center;"><i class="fa-solid fa-calendar-days"></i> Date</th>
            <th style="width: 8%; text-align: center;">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="referral in referrals" :key="referral.ReferralUuid">
            <td class="patient-info" style="text-align: left;">
              <div class="patient-name text-truncate">{{ referral.PatientName }}</div>
              <div class="hospital-name text-truncate">{{ referral.ReferToHospitalName || 'Not Assigned' }}</div>
            </td>
            
            <td style="text-align: center;">
              <div class="grades">
                <div class="grade-container">
                  <div class="grade-label">Left Eye</div>
                  <div class="grade-value">{{ referral.AIGradeLeftEye.replace('_', ' ') || '-' }}</div>
                  <div class="confidence">{{ (referral.ConfidenceLevelLeftEye * 100).toFixed(1) }}%</div>
                </div>
                <div class="grade-divider"></div>
                <div class="grade-container">
                  <div class="grade-label">Right Eye</div>
                  <div class="grade-value">{{ referral.AIGradeRightEye.replace('_', ' ') || '-' }}</div>
                  <div class="confidence">{{ (referral.ConfidenceLevelRightEye * 100).toFixed(1) }}%</div>
                </div>
              </div>
            </td>

            <td style="text-align: center;">
              <span class="priority-badge" :class="referral.isHighPriority ? 'high' : 'normal'">
                {{ referral.isHighPriority ? 'High Priority' : 'Normal' }}
              </span>
            </td>

            <td style="text-align: center;">
              <span class="status-badge" :class="getStatusBadgeClass(referral)">
                {{ getStatusText(referral) }}
              </span>
            </td>

            <td style="text-align: center;">{{ formatDate(referral.created_at) }}</td>

            <td style="text-align: center;">
              <div class="action-buttons">
                <button class="details-btn" @click="$router.push(`/referral/history/${referral.ReferralUuid}`)">
                  <i class="fas fa-external-link-alt"></i>
                </button>
                <button class="details-btn delete" @click="handleDelete(referral.ReferralUuid)">
                  <i class="fa fa-trash-o"></i>
                </button>
              </div>
            </td>

          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { getAllReferral, removeReferral } from '@/services/referralServices'
import SearchBar from '@/components/SearchBar.vue'
import TitleHeader from '@/components/TitleHeaderNoBtn.vue'
import { FileTextIcon } from 'lucide-vue-next'

export default {
  components: {
    SearchBar,
    TitleHeader,
    FileTextIcon
  },
  name: 'Referral',
  data() {
    return {
      referrals: [],
      searchQuery: ''
    }
  },
  methods: {
    async fetchReferrals() {
      try {
        const response = await getAllReferral()
        this.referrals = response.data.reverse()      
      } catch (error) {
        console.error('Error fetching referrals:', error)
      }
    },
    formatDate(dateString) {
      if (!dateString) return '-'
      return new Date(dateString).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      })
    },
    getStatusBadgeClass(referral) {
      if (referral.isCompleted) return 'bg-success'
      if (referral.isAlerted) return 'bg-warning'
      if (referral.isPrinted) return 'bg-info'
      return 'bg-secondary text-white'
    },
    getStatusText(referral) {
      if (referral.isCompleted) return 'Completed'
      if (referral.isAlerted) return 'Alerted'
      if (referral.isPrinted) return 'Printed'
      return 'Pending'
    },
    get_search_referral(search_query){
      this.searchQuery = search_query
      if(search_query){
        this.referrals = this.referrals.filter(referral => {
        const patientName = referral.PatientName.toLowerCase()
        const hospitalName = referral.ReferToHospitalName.toLowerCase()
        const searchQuery = this.searchQuery.toLowerCase()
        return patientName.includes(searchQuery) || hospitalName.includes(searchQuery)
      })
      }
      else{
        this.fetchReferrals();
      }
    },
    async handleDelete(referralUuid) {
      if (confirm('Are you sure you want to delete this referral?')) {
        const response_remove = await removeReferral(referralUuid);
        console.log(response_remove);

        if (response_remove.status == 'success') {
          this.fetchReferrals()
        }
      }
    }

  },
  mounted() {
    this.fetchReferrals()
  }
}
</script>

<style scoped>
.referral-container {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.title {
  font-size: 1.75rem;
  font-weight: 600;
  color: #1a1a1a;
}

.actions {
  display: flex;
  gap: 1rem;
}

.search-box {
  position: relative;
  width: 300px;
}

.search-box input {
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 2.5rem;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
}

.search-box i {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
}

.refresh-btn {
  padding: 0.75rem;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background: var(--primary-color);
  cursor: pointer;
  transition: all 0.3s ease;
}

.refresh-btn:hover {
  background: var(--primary-hover);
  transform: rotate(180deg);
  color: var(--primary-color);
}

.table-container {
  width: 100%;
  overflow-x: auto;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
}

.referral-table {
  width: 100%;
  min-width: 1000px;
  border-collapse: collapse;
}

.referral-table th {
  background: #f8f9fa;
  padding: 1rem;
  font-weight: 600;
  color: #444;
  border-bottom: 2px solid #eee;
}

.referral-table td {
  padding: 1rem;
  border-bottom: 1px solid #eee;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.patient-name {
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 0.25rem;
}

.hospital-name {
  font-size: 0.875rem;
  color: #6c757d;
}

.grades {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.grade-container {
  text-align: center;
}

.grade-label {
  font-size: 0.75rem;
  color: #6c757d;
  margin-bottom: 0.25rem;
}

.grade-value {
  font-weight: 600;
  color: #2c3e50;
}

.confidence {
  font-size: 0.75rem;
  color: #6c757d;
}

.grade-divider {
  width: 1px;
  height: 40px;
  background: #eee;
}

.priority-badge, .status-badge {
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.875rem;
  font-weight: 500;
  display: inline-block;
}

.priority-badge.high {
  background: #fff2f0;
  color: #cf1322;
}

.priority-badge.normal {
  background: #f6ffed;
  color: #389e0d;
}

.bg-success {
  background: #f6ffed;
  color: #389e0d;
}

.bg-warning {
  background: #fffbe6;
  color: #d48806;
}

.bg-info {
  background: #e6f7ff;
  color: #1890ff;
}

.bg-secondary {
  background: #f5f5f5;
  color: #595959;
}

.details-btn {
  padding: 0.5rem;
  border-radius: 6px;
  background: var(--primary-color);
  color: white;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.details-btn:hover {
  background: var(--primary-hover);
  transform: translateY(-1px);
}

.action-buttons {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}

.details-btn.delete {
  background: #ff4d4f;
}

.details-btn.delete:hover {
  background: #ff7875;
}
</style>
