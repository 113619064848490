import axios from 'axios';
import { useAuthStore } from '@/stores/authStore';

const BACKEND_URL = 'https://drmatabackend-584749671098.asia-southeast1.run.app';

export const registerReferral = async (refData, retryAttempt = false) => {
    const authStore = useAuthStore();

    try {
      const response = await axios.post(`${BACKEND_URL}/drmata_user/referral`, refData, {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });
  
      return response.data; // Return response data upon successful registration
      
      } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return registerReferral(refData, true); // Retry after refreshing
        }
        throw error;
    }
};

export const getAllReferral = async (retryAttempt = false) => {
    const authStore = useAuthStore();
  
    try {
        const response = await axios.get(`${BACKEND_URL}/drmata_user/referrals`, {
        headers: {
            Authorization: `Bearer ${authStore.accessToken}`,
        },
        });
    
        return response.data;
  
    } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return getAllReferral(true); // Retry after refreshing
        }
        throw error;
    }
  }

export const getReferral = async (referralUuid, retryAttempt = false) => {
  const authStore = useAuthStore();

  try {
      const response = await axios.get(`${BACKEND_URL}/drmata_user/referral/${referralUuid}`, {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });
      return response.data;

  } catch (error) {
      if (error.response?.status === 401 && !retryAttempt) {
          // Token might be expired, attempt to refresh
          await authStore.refreshAccessToken();
          return getReferral(referralUuid, true); // Retry after refreshing
      }
      throw error;
  }
}

export const removeReferral = async (referralUuid, retryAttempt = false) => {
  const authStore = useAuthStore();

  try {
      const response = await axios.patch(`${BACKEND_URL}/drmata_user/referral/deactivate/${referralUuid}`, {}, {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });      return response.data;

  } catch (error) {
      if (error.response?.status === 401 && !retryAttempt) {
          // Token might be expired, attempt to refresh
          await authStore.refreshAccessToken();
          return removeReferral(referralUuid, true); // Retry after refreshing
      }
      throw error;
  }
}