<template>
    <ImageViewer
        v-model:show="showViewer"
        :image="selectedImage ? selectedImage : null"
        :width="selectedImage ? selectedImage.Width : ''"
        :height="selectedImage ? selectedImage.Height : ''"
        />

    <div class="container">
        <TitleHeader :title="`Case Comparison`" class="mx-4"/>
        <div class="case-compare">

            <div v-if="isLoading" class="comparison-form mb-5">
                <div class="text-center py-4">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <p class="mt-2">Fetching sessions...</p>
                </div>
            </div>
        
            <!-- Selection Form -->
            <div v-else class="comparison-form mb-5">
              <div class="row">
                <div class="col-md-5">
                  <div class="form-group">
                    <label class="modern-label">First Case</label>
                    <select v-model="selectedCase1" class="form-select modern-select">
                      <option value="">Select first case</option>
                      <option v-for="session in sessions" :key="session.SessionUuid" :value="session">
                        {{ formatDate(session.SessionDate) }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group">
                    <label class="modern-label">Second Case</label>
                    <select v-model="selectedCase2" class="form-select modern-select">
                      <option value="">Select second case</option>
                      <option v-for="session in sessions" :key="session.SessionUuid" :value="session"
                        :disabled="session.SessionUuid === selectedCase1?.SessionUuid">
                        {{ formatDate(session.SessionDate) }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-2 d-flex align-items-end">
                  <button @click="compareCase" :disabled="!selectedCase1 || !selectedCase2" 
                    class="btn modern-btn w-100">
                    Compare
                  </button>
                </div>
              </div>
            </div>
        
            <!-- Comparison Results -->
            <div v-if="showComparison" class="comparison-results">
              <div class="row mb-4">
                <div class="col-md-6 mb-3">
                  <div class="case-card">
                    <h4>First Case - {{ formatDate(selectedCase1.SessionDate) }}</h4>
                    <p class="notes">{{ selectedCase1.SessionNotes || 'No notes' }}</p>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="case-card">
                    <h4>Second Case - {{ formatDate(selectedCase2.SessionDate) }}</h4>
                    <p class="notes">{{ selectedCase2.SessionNotes || 'No notes' }}</p>
                  </div>
                </div>
              </div>
        
              <div class="row">
                <div class="col-md-6">
                  <div class="images-section">
                    <h5 class="mb-3">First Case Images</h5>
                    <div v-for="image in selectedCase1.cases" :key="image.MediaUuid" class="image-card mb-4">
                      <div class="image-container">
                        <img :src="image.SignedUrl" :alt="image.FileName" class="img-fluid mb-2">
                        <button class="zoom-btn" @click="openViewer(image)">
                          <i class="fas fa-search-plus"></i>
                        </button>
                      </div>
                      <div class="image-info">
                        <div class="status-badges">
                          <span :class="['status-badge', image.AIAbnormality === 'normal' ? 'normal' : 'abnormal']">
                            {{ image.AIAbnormality }}
                          </span>
                          <span :class="['grade-badge', getGradeClass(image.AIgrading)]">
                            {{ image.AIgrading.replace('_', ' ') }}
                          </span>
                        </div>
                        <span class="confidence">{{ (image.AIConfidence * 100).toFixed(1) }}% confidence</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="images-section">
                    <h5 class="mb-3">Second Case Images</h5>
                    <div v-for="image in selectedCase2.cases" :key="image.MediaUuid" class="image-card mb-4">
                      <div class="image-container">
                        <img :src="image.SignedUrl" :alt="image.FileName" class="img-fluid mb-2">
                        <button class="zoom-btn" @click="openViewer(image)">
                          <i class="fas fa-search-plus"></i>
                        </button>
                      </div>
                      <div class="image-info">
                        <div class="status-badges">
                          <span :class="['status-badge', image.AIAbnormality === 'normal' ? 'normal' : 'abnormal']">
                            {{ image.AIAbnormality }}
                          </span>
                          <span :class="['grade-badge', getGradeClass(image.AIgrading)]">
                            {{ image.AIgrading.replace('_', ' ') }}
                          </span>
                        </div>
                        <span class="confidence">{{ (image.AIConfidence * 100).toFixed(1) }}% confidence</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { getSessionImageByPatient } from '@/services/sessionServices'
import ImageViewer from '@/components/ImageViewer.vue'
import TitleHeader from '@/components/TitleHeader.vue'

export default {
  name: 'CaseCompare',
  components: {
    ImageViewer,
    TitleHeader
  },
  
  data() {
    return {
        isLoading: true, 
        sessions: [],
        selectedCase1: null,
        selectedCase2: null,
        showComparison: false,
        showViewer: false,
        selectedImage: null
    }
  },

  methods: {
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short', 
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      })
    },

    compareCase() {
      this.showComparison = true
    },

    openViewer(image) {
      this.selectedImage = image
      this.showViewer = true
    },

    getGradeClass(grade) {
      switch(grade) {
        case 'Normal': return 'grade-normal'
        case 'Grade_1': return 'grade-1'
        case 'Grade_2': return 'grade-2'
        default: return ''
      }
    },

    async fetchSessions() {
        this.isLoading = true
        try {
            const patientUuid = this.$route.params.uuid
            const response = await getSessionImageByPatient(patientUuid)
            if (response.status === 'success') {
                // Sort sessions by date in descending order
                this.sessions = response.sessions.sort((a, b) => 
                    new Date(b.SessionDate) - new Date(a.SessionDate)
                )
                
                // Select the two most recent cases if available
                if (this.sessions.length >= 2) {
                    this.selectedCase1 = this.sessions[1]
                    this.selectedCase2 = this.sessions[0]
                    this.showComparison = true
                }
            }
        } catch (error) {
            console.error('Error fetching sessions:', error)
        } finally {
            this.isLoading = false
        }
    }
  },

  mounted() {
    this.fetchSessions()
  }
}

</script>
  
<style scoped>
  .case-compare {
    padding: 2rem;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  .modern-title {
    font-size: 2rem;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 1.5rem;
  }
  
  .modern-label {
    font-weight: 500;
    color: #4a5568;
    margin-bottom: 0.5rem;
  }
  
  .modern-select {
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    padding: 0.6rem;
    background-color: #fff;
    transition: all 0.3s;
  }
  
  .modern-select:focus {
    border-color: #4299e1;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.1);
  }
  
  .modern-btn {
    background-color: #4299e1;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 0.6rem 1.5rem;
    transition: all 0.3s;
  }
  
  .modern-btn:hover:not(:disabled) {
    background-color: #3182ce;
    transform: translateY(-1px);
  }
  
  .modern-btn:disabled {
    background-color: #cbd5e0;
  }
  
  .case-card {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  }
  
  .case-card h4 {
    color: #2d3748;
    margin-bottom: 0.5rem;
  }
  
  .notes {
    color: #718096;
    font-size: 0.9rem;
  }
  
  .image-card {
    background: white;
    border-radius: 12px;
    padding: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  }
  
  .image-card img {
    border-radius: 8px;
    width: 100%;
    height: 300px; /* Fixed height */
    object-fit: cover; /* Maintains aspect ratio */
  }
  
  .image-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
  }
  
  .grade {
    font-weight: 600;
    color: #4a5568;
  }
  
  .confidence {
    color: #718096;
    font-size: 0.9rem;
  }
  
  .comparison-form {
    background: white;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    margin-bottom: 2rem;
  }

  .image-container {
    position: relative;
    width: 100%;
    height: 300px;
  }
  
  .zoom-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(63, 63, 63, 0.9);
    border: none;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .zoom-btn:hover {
    background: rgb(199, 199, 199);
    transform: scale(1.1);
  }

  .status-badges {
    display: flex;
    gap: 8px;
  }
  
  .status-badge {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.85rem;
    font-weight: 500;
    text-transform: capitalize;
  }
  
  .status-badge.normal {
    background-color: #10B981;
    color: white;
  }
  
  .status-badge.abnormal {
    background-color: #EF4444;
    color: white;
  }
  
  .grade-badge {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.85rem;
    font-weight: 500;
  }
  
  .grade-normal {
    background-color: #10B981;
    color: white;
  }
  
  .grade-1 {
    background-color: #F59E0B;
    color: white;
  }
  
  .grade-2 {
    background-color: #EF4444;
    color: white;
  }
  
</style>
  