<script>
  import { getSessionImage } from '@/services/sessionServices'
  import { getCurrentPatient } from '@/services/patientServices'
  import TitleHeader from '@/components/TitleHeader.vue'
  import LoadingSmall from '@/components/LoadingSmall.vue'
  import AIoverlay from '@/components/AIoverlay.vue'
  import ImageViewer from '@/components/ImageViewer.vue'
  import CaseFeedback from '@/pages/Session/CaseFeedback.vue'
  
  export default {
    name: 'CaseImage',

    components: {
      TitleHeader,
      LoadingSmall,
      AIoverlay,
      ImageViewer,
      CaseFeedback
    },
    
    data() {
      return {
        sessionData: null,
        sessionDataforSummary: null,
        patientData: null,
        selectedImage: null,
        showImage: false,
        showAIOverlay: false ,
        highestGradeLeft: '',
        highestGradeRight: '',
        showFeedbackOverlay: false,
        feedbackMedia: null,
      }
    },

    // Add this in the component's script section
    computed: {
      highestOverallGrade() {
        const grades = ['Grade_2', 'Grade_1', 'Normal'];
        for (const grade of grades) {
          if (this.highestGradeLeft === grade || this.highestGradeRight === grade) {
            return grade;
          }
        }
        return '';
      }
    },
  
    methods: {
      handleFeedback(imageData, outcome) {
        console.log("Image feedback data:", imageData);
        this.showFeedbackOverlay = true
        this.feedbackMedia = imageData
        this.feedbackMedia.Outcome = outcome
      },

      handleFeedbackSubmitted(data){
        console.log(data);
      },

      async fetchSessionImages() {
        try {
          const uuid = this.$route.params.uuid
          const response = await getSessionImage(uuid)
          console.log(response)
          this.highestGradeLeft = response.highestLeftEyeGrading
          this.highestGradeRight = response.highestRightEyeGrading
          this.sessionData = response.images
          console.log(this.sessionData)

          this.sessionDataforSummary = response
          delete this.sessionDataforSummary.images

          //console.log(this.sessionData)
        } catch (error) {
          console.error('Error fetching session images:', error)
          this.sessionData = []
        }
      },

      async fetchCurrentPatient(){
        try {
          const uuid = this.$route.params.patientUuid
          const response = await getCurrentPatient(uuid)
          this.patientData = response.data
          console.log(this.patientData);
        } catch (error) {
          console.error('Error fetching patient data:', error)
          this.patientData = []
        }
      },
      generateReferral() {
        this.$router.push(`/referral/form/${this.$route.params.patientUuid}/${this.$route.params.uuid}`);
      },
      closeFeedbackOverlay(){
        this.showFeedbackOverlay = false
        this.feedbackMedia = null
      }
    },
  
    mounted() {
      this.fetchSessionImages()
      this.fetchCurrentPatient()
    }
  }
</script>

<template>

  <CaseFeedback 
    v-if="showFeedbackOverlay"
    :media_data="feedbackMedia"
    @close="closeFeedbackOverlay"
    @audit-submitted="handleFeedbackSubmitted"
  />

  <TitleHeader :title="`Case Image`"/>

  <div class="case-images-container">
    <!-- PATIENT CARD -->
    <div class="patient-card" v-if="patientData">
      <div class="patient-header">
        <i class="fas fa-user-circle"></i>
        <h3>Patient Information</h3>
      </div>
      
      <div class="patient-details-grid">
        <div class="detail-item">
          <span class="detail-label">Name</span>
          <span class="detail-value">{{ patientData.PatientName }}</span>
        </div>
        <div class="detail-item">
          <span class="detail-label">Age</span>
          <span class="detail-value">{{ patientData.PatientAgeAtScreening }} years</span>
        </div>
        <div class="detail-item">
          <span class="detail-label">Date of Birth</span>
          <span class="detail-value">{{ new Date(patientData.PatientDOB).toLocaleDateString() }}</span>
        </div>
        <div class="detail-item">
          <span class="detail-label">Diabetes Type</span>
          <span class="detail-value">{{ patientData.TypeOfDiabetes || 'None' }}</span>
        </div>
      </div>
    </div>

    <!-- PATIENT CARD LOADING -->
    <div v-else class="patient-card">
      <LoadingSmall title="Loading Patients Data..." />
    </div>

    <div class="grading-legend">
      <div class="patient-header">
        <i class="fa-solid fa-circle-exclamation"></i>
        <h3>Grading Legend</h3>
      </div>
      <div class="legend-items">
        <div class="legend-item" :class="{ 'highest-grade': this.highestOverallGrade === 'Normal' }">
          <span class="legend-badge" style="background: #007bff"></span>
          <span>Normal: Repeat fundus photo within a year</span>
        </div>
        <div class="legend-item" :class="{ 'highest-grade': this.highestOverallGrade === 'Grade_1' }">
          <span class="legend-badge" style="background: #ffc107"></span>
          <span>Grade 1 (Mild to Moderate NPDR): Repeat fundus photo within 6 months</span>
        </div>
        <div class="legend-item" :class="{ 'highest-grade': this.highestOverallGrade === 'Grade_2' }">
          <span class="legend-badge" style="background: #dc3545"></span>
          <div class="legend-content">
            <span>Grade 2 (Severe NPDR/PDR/ADED/Maculopathy): To refer ophthalmologist</span>
            <button class="referral-btn-compact" @click="generateReferral">
              <i class="fas fa-file-medical"></i> Refer
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- REFERRAL BUTTON -->
    <!-- <div class="button-container d-flex justify-content-start align-items-left">
      <router-link 
        :to="`/referral/form/${this.$route.params.patientUuid}/${this.$route.params.uuid}`" 
        class="referral-btn mx-1">
        <i class="fas fa-file-medical"></i>
        <span>Generate Referral Form</span>
      </router-link>

      <div class="ai-btn mx-1" style="cursor: pointer;" @click="showAIOverlay = true">
        <i class="fa-solid fa-brain"></i>
        <span>Generate AI Summary</span>
      </div>
    </div> -->
  
    <!-- IMAGES SECTION -->
    <div v-if="sessionData && sessionData.length > 0" class="images-container">
      <div class="single-row-container">

        <!-- Right Eye Group -->
        <div v-if="sessionData.some(img => img.EyeType === 1)" class="eye-group right-eye">
          <div class="eye-label">
            <i class="fas fa-eye"></i>
            <span>Right Eye</span>
          </div>
          <div class="images-row">
            <div v-for="image in sessionData.filter(img => img.EyeType === 1)" 
                 :key="image.MediaUuid" 
                 class="image-card">
              <div class="image-wrapper">
                  <img :src="image.SignedUrl" :alt="image.FileName" />
                  <button class="zoom-btn" @click="selectedImage = image; showImage= true">
                    <i class="fas fa-search-plus"></i>
                  </button>
                  <!-- Add the quality warning overlay -->
                  <div v-if="image.ImageQuality && image.ImageQuality < 0.3" class="quality-warning">
                    <i class="fas fa-exclamation-triangle"></i>
                    <span class="warning-tooltip">Low quality image detected, <br>it may affect the AI result</span>
                  </div>
              </div>
              <div class="image-details">
                <div class="analysis-results">
                  <div v-if="image.AIgrading" class="result-item">
                    <span class="label">Grading:</span>
                    <span class="badge grading" :data-grade="image.AIgrading">
                      {{ image.AIgrading.replace('_', ' ') }}
                    </span>
                  </div>
                  <div v-if="image.AIConfidence" class="result-item">
                    <span class="label">Confidence:</span>
                    <span class="confidence">{{ (image.AIConfidence * 100).toFixed(1) }}%</span>
                  </div>
                    <i class="fas fa-info-circle tooltip-icon">
                      <span class="tooltip-text">
                        Eye Field: {{ image.EyeField }}<br>
                        Field of View: {{ image.FieldOfViewAngle }}°
                      </span>
                    </i>
                </div>
                <div class="feedback-buttons">
                  <button class="thumb-btn thumb-up" @click="handleFeedback(image, 'positive')">
                    <i class="fas fa-thumbs-up"></i>
                  </button>
                  <button class="thumb-btn thumb-down" @click="handleFeedback(image, 'negative')">
                    <i class="fas fa-thumbs-down"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Left Eye Group -->
        <div v-if="sessionData.some(img => img.EyeType === 0)" class="eye-group left-eye">
          <div class="eye-label">
            <i class="fas fa-eye"></i>
            <span>Left Eye</span>
          </div>
          <div class="images-row">
            <div v-for="image in sessionData.filter(img => img.EyeType === 0)" 
                 :key="image.MediaUuid" 
                 class="image-card">
              <div class="image-wrapper">
                  <img :src="image.SignedUrl" :alt="image.FileName" />
                  <button class="zoom-btn" @click="selectedImage = image; showImage= true">
                    <i class="fas fa-search-plus"></i>
                  </button>
                  <!-- Add the quality warning overlay -->
                  <div v-if="image.ImageQuality && image.ImageQuality < 0.3" class="quality-warning">
                    <i class="fas fa-exclamation-triangle"></i>
                    <span class="warning-tooltip">Low quality image detected, <br>it may affect the AI result</span>
                  </div>
              </div>
              <div class="image-details">
                <div class="analysis-results">
                  <div v-if="image.AIgrading" class="result-item">
                    <span class="label">Grading:</span>
                    <span class="badge grading" :data-grade="image.AIgrading">
                      {{ image.AIgrading.replace('_', ' ') }}
                    </span>
                  </div>
                  <div v-if="image.AIConfidence" class="result-item">
                    <span class="label">Confidence:</span>
                    <span class="confidence">{{ (image.AIConfidence * 100).toFixed(1) }}%</span>
                  </div>
                  <i class="fas fa-info-circle tooltip-icon">
                    <span class="tooltip-text">
                      Eye Field: {{ image.EyeField ? image.EyeField : 'none' }}<br>
                      Field of View: {{ image.FieldOfViewAngle ? image.EyeField : 'none' }}°
                    </span>
                  </i>
                </div>
                <div class="feedback-buttons">
                  <button class="thumb-btn thumb-up" @click="handleFeedback(image, 'good')">
                    <i class="fas fa-thumbs-up"></i>
                  </button>
                  <button class="thumb-btn thumb-down" @click="handleFeedback(image, 'bad')">
                    <i class="fas fa-thumbs-down"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Unspecified Eye Group -->

        <div v-if="sessionData.some(img => img.EyeType === 2)" class="eye-group right-eye">
          <div class="eye-label">
            <i class="fas fa-eye"></i>
            <span>Unspecified Eye</span>
          </div>
          <div class="images-row">
            <div v-for="image in sessionData.filter(img => img.EyeType === 2)" 
                 :key="image.MediaUuid" 
                 class="image-card">
              <div class="image-wrapper">
                  <img :src="image.SignedUrl" :alt="image.FileName" />
                  <button class="zoom-btn" @click="selectedImage = image; showImage= true">
                    <i class="fas fa-search-plus"></i>
                  </button>
                  <!-- Add the quality warning overlay -->
                  <div v-if="image.ImageQuality && image.ImageQuality < 0.3" class="quality-warning">
                    <i class="fas fa-exclamation-triangle"></i>
                    <span class="warning-tooltip">Low quality image detected, <br>it may affect the AI result</span>
                  </div>
              </div>
              <div class="image-details">
                <div class="analysis-results">
                  <div v-if="image.AIgrading" class="result-item">
                    <span class="label">Grading:</span>
                    <span class="badge grading" :data-grade="image.AIgrading">
                      {{ image.AIgrading.replace('_', ' ') }}
                    </span>
                  </div>
                  <div v-if="image.AIConfidence" class="result-item">
                    <span class="label">Confidence:</span>
                    <span class="confidence">{{ (image.AIConfidence * 100).toFixed(1) }}%</span>
                  </div>
                    <i class="fas fa-info-circle tooltip-icon">
                      <span class="tooltip-text">
                        Eye Field: {{ image.EyeField }}<br>
                        Field of View: {{ image.FieldOfViewAngle }}°
                      </span>
                    </i>
                </div>
                <div class="feedback-buttons">
                  <button class="thumb-btn thumb-up" @click="handleFeedback(image, 'positive')">
                    <i class="fas fa-thumbs-up"></i>
                  </button>
                  <button class="thumb-btn thumb-down" @click="handleFeedback(image, 'negative')">
                    <i class="fas fa-thumbs-down"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- Images Section Loading -->
    <div v-else class="images-container">
      <LoadingSmall title="Loading Images..." />
    </div>
  
    <!-- Image Overlay -->
    <Transition name="fade">

      <ImageViewer
        v-model:show="showImage"
        :image="selectedImage ? selectedImage : null "
        :width="selectedImage ? selectedImage.Width : '' "
        :height="selectedImage ? selectedImage.Height : '' "
        :patientUuid="this.$route.params.patientUuid ? this.$route.params.patientUuid : ''"
        :sessionUuid="this.$route.params.uuid ? this.$route.params.uuid : ''"
      />
    </Transition>

    <Transition name="fade">
        <AIoverlay 
          v-model:show="showAIOverlay"
          v-model:patient_data="patientData"
          v-model:session_data="sessionDataforSummary"
          />
    </Transition>


  </div>
</template>

<style scoped>
.single-row-container {
  display: flex;
  gap: 2rem;
  overflow-x: auto;
  padding: 1rem;
  margin: 1rem 0;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  justify-content: center; /* Center the eye groups */
}

.eye-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1; /* Make eye groups take equal space */
  max-width: 850px; /* Limit maximum width */
}

.images-row {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center; /* Center the image cards */
}

.image-card {
  flex: 0 1 calc(50% - 0.5rem);
  min-width: 300px;
  max-width: calc(50% - 0.5rem);
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  transition: transform 0.2s ease;
}

.eye-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  background: #f8f9fa;
  border-radius: 8px;
  color: var(--primary-color);
  font-weight: 600;
}

.image-card:hover {
  transform: translateY(-2px);
}

.image-wrapper {
  position: relative;
  height: 200px;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
}

.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.quality-warning {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #ffc107;
  cursor: help;
}

.quality-warning .warning-tooltip {
  visibility: hidden;
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 0.8rem;
  white-space: nowrap;
  z-index: 10;
}

.quality-warning:hover .warning-tooltip {
  visibility: visible;
}

.image-details {
  padding: 1rem;
}

.analysis-results {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.result-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1024px) {
  .single-row-container {
    flex-direction: column;
  }

  .eye-group {
    width: 100%;
  }

  .images-row {
    overflow-x: auto;
    padding-bottom: 1rem;
  }
}

/* Keep your existing styles for overlay, tooltips, and transitions */
</style>
  
<style scoped>
  .case-images-container {
    padding: 2rem;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  .images-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .eye-section {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  }
  
  .eye-header {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #eee;
  }
  
  .eye-header i {
    color: var(--primary-color);
    font-size: 1.25rem;
  }
  
  .eye-header h3 {
    margin: 0;
    color: #2c3e50;
  }
  
  .images-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
  }
  
  .image-card {
    max-width: 400px;
    width: 100%;
  }
  
  @media (max-width: 768px) {
    .images-grid {
      grid-template-columns: 1fr;
    }
  }  
  
  .image-card:hover {
    transform: translateY(-4px);
  }
  
  .image-wrapper {
    height: 250px;
    overflow: hidden;
  }
  
  .image-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .image-details {
    padding: 1.5rem;
  }
  
  .image-name {
    margin: 0 0 1rem 0;
    color: #2c3e50;
    font-size: 1rem;
  }
  
  .analysis-results {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
  
  .result-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .label {
    color: #666;
    font-weight: 500;
  }
  
  .confidence {
    color: var(--primary-color);
    font-weight: 600;
  }
  
  .no-images, .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 3rem;
    text-align: center;
    color: #6c757d;
  }
  
  .no-images i {
    color: #adb5bd;
  }
  
  @media (max-width: 768px) {
    .case-images-container {
      padding: 1rem;
    }
  
    .images-grid {
      grid-template-columns: 1fr;
    }
  }
</style>
  
<!-- PAtient Details -->
<style scoped>
.patient-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  margin-bottom: 2rem;
  padding: 1.5rem;
}

.patient-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
}

.patient-header i {
  font-size: 1.5rem;
  color: var(--primary-color);
}

.patient-header h3 {
  margin: 0;
  color: #2c3e50;
}

.patient-details-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.detail-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.detail-label {
  font-size: 0.9rem;
  color: #666;
  font-weight: 500;
}

.detail-value {
  font-size: 1.1rem;
  color: #2c3e50;
  font-weight: 600;
}

@media (max-width: 768px) {
  .patient-details-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  
  .detail-item {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .detail-item:last-child {
    border-bottom: none;
  }
}

</style>

<style scoped>
.image-wrapper {
  position: relative;
  overflow: hidden;
}

.zoom-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.zoom-btn i {
  font-size: 14px;
}

.zoom-btn:hover {
  background: rgba(0, 0, 0, 0.8);
  transform: scale(1.1);
}

.image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Increased from 1000 to 9999 */
}

.overlay-content {
  position: relative;
  max-width: 90vw;
  max-height: 90vh;
}

.overlay-content img {
  max-width: 100%;
  max-height: 90vh;
  object-fit: contain;
}

.close-btn {
  position: absolute;
  top: -40px;
  right: 0;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 8px;
}

.close-btn:hover {
  color: #ff4444;
}

</style>

<!-- Transition -->
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

<!-- TOOLTIP -->
<style scoped>
.image-details {
  padding: 1rem 1rem 2.5rem 1rem;
  position: relative;
  overflow: visible; /* Allow tooltip to overflow */
}

.tooltip-icon {
  position: absolute;
  right: 1rem;
  bottom: 0.8rem;
  color: var(--primary-color);
  cursor: help;
  z-index: 1;
}

.tooltip-text {
  visibility: hidden;
  position: fixed;
  left: 50%;
  transform: translate(-50%, -100%);
  background: #333;
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 0.8rem;
  font-family: 'Courier New', Courier, monospace !important;
  white-space: nowrap;
  z-index: 99999;
  opacity: 0;
  transition: opacity 0.2s ease;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
  pointer-events: none;
}

.tooltip-text {
  white-space: nowrap;
  text-align: left;
  min-width: 150px;
  line-height: 1.4;
}

.tooltip-icon:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip-text::after {
  content: '';
  position: absolute;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

</style>

<!-- BADGE GRADING -->
<style scoped>
.badge.grading {
  padding: 0.3rem 0.8rem;
  border-radius: 6px;
  font-weight: 600;
  color: white;
}

.badge.grading[data-grade="Normal"] {
  background: #007bff;
}

.badge.grading[data-grade="Grade_1"] {
  background: #ffc107;
}

.badge.grading[data-grade="Grade_2"] {
  background: #dc3545;
}

.grading-legend {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.grading-legend h4 {
  margin: 0 0 1rem 0;
  color: #2c3e50;
}

.legend-items {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.legend-badge {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  flex-shrink: 0;
}
</style>

<style scoped>
.button-container {
  margin: 1rem 0;
  text-align: right;
}

.referral-btn {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 500;
  transition: background-color 0.2s;
}

.referral-btn:hover {
  background-color: var(--primary-hover);
}

.ai-btn {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #9a57dd;
  color: white;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 500;
  transition: background-color 0.2s;
}

.ai-btn:hover {
  background-color: #6e2b92;
}

.referral-btn i {
  font-size: 1.1rem;
}
</style>

<style scoped>
.legend-item {
  color: #888;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.highest-grade {
  color: #2c3e50;
  font-weight: 700;
}

.legend-badge {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  display: inline-block;
}
</style>


<style scoped>
.legend-content {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  width: 100%;
}

.referral-btn-compact {
  padding: 0.35rem 0.75rem;
  background: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 0.8rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.3rem;
  white-space: nowrap;
  transition: all 0.2s ease;
}

.referral-btn-compact:hover {
  background: #c82333;
  transform: translateY(-1px);
}
</style>

<style scoped>
.overlay-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90vw;
  max-height: 90vh;
}

.image-controls {
  position: absolute;
  bottom: -80px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 2rem;
  background: rgba(0, 0, 0, 0.7);
  padding: 1rem 2rem;
  border-radius: 8px;
}

.control-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.control-label {
  color: white;
  font-size: 0.9rem;
  font-weight: 500;
}

.control-value {
  color: white;
  font-size: 0.8rem;
}

input[type="range"] {
  width: 150px;
  cursor: pointer;
}

.image-details {
  position: relative;
  padding-bottom: 50px; /* Make space for button */
}

.feedback-buttons {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.thumb-btn {
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
  color: white;
}

.thumb-up {
  border: 1px solid var(--primary-color);
  background: none;
  color: var(--primary-color);
}

.thumb-up:hover {
  background: var(--primary-color);;
  color: white;
  transform: translateY(-2px);
}

.thumb-down {
  border: 1px solid var(--primary-color);
  background: none;
  color: var(--primary-color);
}

.thumb-down:hover {
  background: var(--primary-color);;
  color: white;
  transform: translateY(-2px);
}
</style>
