import axios from 'axios';
import { useAuthStore } from '../stores/authStore';

const BACKEND_URL = "https://drmatabackend-584749671098.asia-southeast1.run.app"
// make it into constant export from 1 file in util folder

export const getAllClinics = async (retryAttempt = false) => {
    const authStore = useAuthStore();

    try {
        const response = await axios.get(`${BACKEND_URL}/nora_superadmin/clinics`, {
        headers: {
            Authorization: `Bearer ${authStore.accessToken}`,
        },
        });
    
        return response.data;
    } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return getAllClinics(true); // Retry after refreshing
        }
        throw error;
    }
}

export const registerClinics= async (input_data, retryAttempt = false) => {
    const authStore = useAuthStore();
    
    try {
      const response = await axios.post(`${BACKEND_URL}/nora_superadmin/clinics`, input_data, {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });
  
      return response.data; // Return response data upon successful registration
    } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return registerClinics(input_data, retryAttempt, true); // Retry after refreshing
        }
        throw error;
    }
  };

export const editClinics = async (input_data, id, retryAttempt = false) => {
  const authStore = useAuthStore();
  try {
    const response = await axios.put(`${BACKEND_URL}/nora_superadmin/clinics/${ id }`, input_data, {
      headers: {
        Authorization: `Bearer ${authStore.accessToken}`,
      },
    });

    return response.data; // Return response data upon successful registration
  } catch (error) {
      if (error.response?.status === 401 && !retryAttempt) {
          // Token might be expired, attempt to refresh
          await authStore.refreshAccessToken();
          return editClinics(input_data, id, true); // Retry after refreshing
      }
      throw error;
  }
};

export const getCurrentClinic = async (location_uuid, retryAttempt = false) => {
    const authStore = useAuthStore();
  
    try {
      const response = await axios.get(`${BACKEND_URL}/nora_superadmin/clinics/${location_uuid}`, {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });
  
      return response.data;
    } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return getCurrentClinic(location_uuid, true); // Retry after refreshing
        }
        throw error;
    }
};