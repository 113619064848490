import axios from 'axios';
import { useAuthStore } from '@/stores/authStore';

const BACKEND_URL = 'https://drmatabackend-584749671098.asia-southeast1.run.app';

export const registerSession = async (sessionData, retryAttempt = false) => {
    const authStore = useAuthStore();

    try {
      const response = await axios.post(`${BACKEND_URL}/drmata_user/sessions`, sessionData, {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });
  
      return response.data; // Return response data upon successful registration
      
      } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return registerSession(sessionData, true); // Retry after refreshing
        }
        throw error;
    }
}

export const getSessionImage = async (sessionUUID, retryAttempt = false) => {
  const authStore = useAuthStore();

  try {
      const response = await axios.get(`${BACKEND_URL}/drmata_user/cases-by-session?SessionUuid=${sessionUUID}`, {
      headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
      },
      });
  
      return response.data;

  } catch (error) {
      if (error.response?.status === 401 && !retryAttempt) {
          // Token might be expired, attempt to refresh
          await authStore.refreshAccessToken();
          return getSessionImage(sessionUUID, true); // Retry after refreshing
      }
      throw error;
  }
}

export const getPatientSessionV1 = async (pattientUUID, retryAttempt = false) => {
    const authStore = useAuthStore();
  
    try {
        const response = await axios.get(`${BACKEND_URL}/drmata_user/sessions/${pattientUUID}`, {
        headers: {
            Authorization: `Bearer ${authStore.accessToken}`,
        },
        });
    
        return response.data;
  
    } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return getPatientSession(pattientUUID, true); // Retry after refreshing
        }
        throw error;
    }
}

export const getPatientSessionV2 = async (patientUUID, retryAttempt = false) => {
  const authStore = useAuthStore();

    try {
        const response = await axios.get(`${BACKEND_URL}/drmata_user/cases-by-patient?PatientUuid=${patientUUID}&imageURL=false`, {
        headers: {
            Authorization: `Bearer ${authStore.accessToken}`,
        },
        });

        return response.data;

    } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return getPatientSessionV2(patientUUID, true); // Retry after refreshing
        }
        throw error;
    }
}

export const getPatientSessionV3 = async (patientUUID, retryAttempt = false) => {
    const authStore = useAuthStore();

    try {
        const response = await axios.get(`${BACKEND_URL}/drmata_user/cases-by-patient?PatientUuid=${patientUUID}&imageURL=false`, {
            headers: {
                Authorization: `Bearer ${authStore.accessToken}`,
            },
        });

        const abnormalityOrder = {
            'None': 0,
            'normal': 1,
            'abnormal': 2
        };

        const gradingOrder = {
            'None': 0,
            'Normal': 1,
            'Grade_0': 2,
            'Grade_1': 3,
            'Grade_2': 4,
        };

        const sessions = response.data.sessions.map(session => {
            const summary = {
                EyeType0: { highest_abnormality: 'None', highest_grading: 'None', average_confidence: null, case_count: 0 },
                EyeType1: { highest_abnormality: 'None', highest_grading: 'None', average_confidence: null, case_count: 0 },
                EyeType2: { highest_abnormality: 'None', highest_grading: 'None', average_confidence: null, case_count: 0 }
            };

            // Process cases to generate summary
            session.cases.forEach(c => {
                const eyeTypeKey = `EyeType${c.EyeType !== null ? c.EyeType : 2}`; // Use EyeType2 for null or undefined EyeType
                const eyeSummary = summary[eyeTypeKey];

                eyeSummary.case_count += 1;
                eyeSummary.average_confidence = (eyeSummary.average_confidence || 0) + c.AIConfidence;

                // Update highest grading
                if (gradingOrder[c.AIgrading] > gradingOrder[eyeSummary.highest_grading]) {
                    eyeSummary.highest_grading = c.AIgrading;
                }

                // Update highest abnormality
                if (abnormalityOrder[c.AIAbnormality] > abnormalityOrder[eyeSummary.highest_abnormality]) {
                    eyeSummary.highest_abnormality = c.AIAbnormality;
                }
            });

            // Finalize average confidence calculation
            for (const eyeTypeKey in summary) {
                const eyeSummary = summary[eyeTypeKey];
                if (eyeSummary.case_count > 0) {
                    eyeSummary.average_confidence /= eyeSummary.case_count;
                }
            }

            session.summary = summary;
            return session;
        });

        return { sessions };

    } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            await authStore.refreshAccessToken();
            return getPatientSessionV3(patientUUID, true);
        }
        throw error;
    }
}

export const getSessionImageByPatient = async (patientUUID, retryAttempt = false) => {
    const authStore = useAuthStore();
  
      try {
          const response = await axios.get(`${BACKEND_URL}/drmata_user/cases-by-patient?PatientUuid=${patientUUID}&imageURL=true`, {
          headers: {
              Authorization: `Bearer ${authStore.accessToken}`,
          },
          });
  
          return response.data;
  
      } catch (error) {
          if (error.response?.status === 401 && !retryAttempt) {
              // Token might be expired, attempt to refresh
              await authStore.refreshAccessToken();
              return getSessionImageByPatient(patientUUID, true); // Retry after refreshing
          }
          throw error;
      }
}