import axios from 'axios';
import { useAuthStore } from '../stores/authStore';
import { getAllOrgArray } from './orgServices';

const BACKEND_URL = "https://drmatabackend-584749671098.asia-southeast1.run.app"

export const getDashboard = async (period, retryAttempt = false) => {
    const authStore = useAuthStore();
    
    // Calculate dates
    const endDate = new Date();
    const startDate = new Date();
    
    if (period === 'week') {
        startDate.setDate(startDate.getDate() - 7);
    } else if (period === 'month') {
        startDate.setMonth(startDate.getMonth() - 1);
    }

    try {
        const response = await axios.get(`${BACKEND_URL}/drmata_user/statistics`, {
            headers: {
                Authorization: `Bearer ${authStore.accessToken}`,
            },
            params: {
                start_date: startDate.toISOString().split('T')[0],
                end_date: endDate.toISOString().split('T')[0]
            }
        });
    
        return response.data.data;
    } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            await authStore.refreshAccessToken();
            return getDashboard(period, true);
        }
        throw error;
    }
}

export const getDashboardbyDate = async (periodStart, periodEnd, organizationid, retryAttempt = false) => {
    const authStore = useAuthStore();
    const userDetails = await authStore.fetchUserDetails();
    const userRole = userDetails.Role;
    let endpoint = '';
    let organization = [];

    if(userRole === 'admin'){
        endpoint = "nora_superadmin/statistics"
        organization = await getAllOrgArray();
    }else{
        endpoint = "drmata_user/statistics"
    }

    try {
        const response = await axios.get(`${BACKEND_URL}/${endpoint}`, {
            headers: {
                Authorization: `Bearer ${authStore.accessToken}`,
            },
            params: {
                start_date: periodStart,
                end_date: periodEnd,
                organization_uuid: organizationid
            }
        });

        if(organization.length>0){
            response.data.data.organization = organization;
        }
    
        return response.data.data;
    } catch (error) {
        if (error.response?.status === 401 && !retryAttempt) {
            await authStore.refreshAccessToken();
            return getDashboardbyDate(periodStart, periodEnd, true);
        }
        throw error;
    }
}





